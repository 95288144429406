<template>
  <div class="max-w-4xl m-auto py-10">
    <button
      v-if="isAdmin()"
      class="bg-transprent text-sm hover:bg-blue-400 text-blue hover:text-white no-underline font-bold py-2 px-4 rounded border border-blue-500"
      @click="addPost()"
      >
        Add Post
    </button>
    <ul class="list-reset mt-4">
      <li class="py-4"
        v-for="post in posts"
        :key="post.id"
        :post="post">
        <div class="flex justify-between">
          <p class="block flex-1 font-mono font-semibold">
            {{ post.title }}
          </p>
          <p class="block flex-1 font-mono font-semibold">
             id: {{ post.id }}
          </p>
        </div>
        <div class=" items-center flex justify-around flex-wrap w-3/5" style="height:100px;">
          <img :src="post.image_url" class="mx-1 border" style="width:50px; height: 50px;" />
          <div class="flex flex-row py-2">
            <button
              @click="routeToEdit(post.id)"
              class="mx-2 block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded block"
              v-if="signedIn()">
              Edit
            </button>
            <button
              class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded block"
              @click="routeToPost(post.slug)">View
            </button>
          </div>
        </div>
        <div class="flex justify-between">
          <p class="block flex-1 font-mono font-semibold">
            {{ getDate(post.updated_at) }}
          </p>
          <p class="block flex-1 font-mono font-semibold">
            slug: {{ post.slug }}
          </p>
        </div>
        <hr class="border border-grey-light my-6" />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'posts',
  data () {
    return {
      posts: [],
      error: ''
    }
  },
  created () {
    if (!this.$store.state.signedIn || this.$store.state.currentUser.role !== "admin") {
      this.$router.replace('/')
    } else {
      this.$http.secured.get('/posts')
        .then(response => { this.posts = response.data })
        .catch(error => this.setError(error, 'Something went wrong'))
    }
    // if (!this.posts.length) {
    //   this.posts[0].title = "Edit this post";
    // }
  },
  methods: {
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    addPost(){
      this.$store.commit('setPostId', null)
      this.routeTo("posts/edit");
    },
    signedIn () {
      return this.$store.state.signedIn
    },
    setError (error, text) {
      this.error = (error.response && error.response.data && error.response.data.error) || text
    },
    getDate(d){
      const date = new Date(d).toLocaleDateString('en-gb',{month: 'long', year: 'numeric', day: 'numeric'})
      return date
    },
    routeTo(route){
      window.location = route;
    },
    //convert this to one function
    routeToEdit(id) {
      this.$store.commit("setPostId", id);
      this.routeTo("/posts/edit");
    },
    routeToPost(slug) {
      this.routeTo("posts/"+slug);
    },
  }
}
</script>
