<template>
  <div class="items-center text-center relative">
    <div
      :class="{ dimbackground: showModal }"
      class="bg-purple-100 py-20"
    >
      <button
        class="bg-green-500 text-white font-bold py-2 px-4 rounded"
        v-if="postId"
        @click="setActive"
      >
        Make Active
      </button>
      <button
        class="bg-red-500 text-white font-bold py-2 px-4 rounded"
        v-if="postId"
        @click="deleteItemCheck"
      >
        Delete
      </button>
      <div class="mx-auto w-4/5 text-center p-4">
        <p class="bg-blue-200 rounded w-2/5 mx-auto py-2 text-blue-500 mb-12" style="font-size:2rem;">Post Editor</p>
        <p
          v-if="title"
          class="bg-blue-200 rounded w-2/5 mx-auto py-2 text-blue-500 mb-12"
          style="font-size:2rem;"
        >
          {{ post.title }}
        </p>
        <form class="bg-white px-8 pt-6 pb-8 mb-4 shadow rounded">
          <img :src="post.image_url" class="w-3/5 mx-auto mt-12 " />
          <label class="block text-gray-700 text-sm font-bold mb-2 p-2" for="image">Upload Preview Image</label>
          <input class="mb-4" type="file" name="image" ref="inputPic" @change="uploadImage()">
          <div class="mb-6">
            <label for="title" class="label">Title</label><br>
            <input type="title" v-model="title" class="input border rounded p-2 w-full" id="title" ref="title" :placeholder="post.title" >
          </div>
          <div class="mb-6">
            <label for="author" class="label">author</label><br>
            <textarea type="textarea" v-model="author" class="input border rounded p-2 w-full" id="author" :placeholder="post.author" />
          </div>
          <div class="mb-6">
            <label for="body" class="label">body</label><br>
            <textarea type="textarea" v-model="body" class="input border rounded p-2 w-full" id="body" :placeholder="post.body" />
          </div>
          <div class="mb-6">
            <label for="blurb" class="label">Blurb</label><br>
            <textarea type="textarea" v-model="blurb" class="input border rounded p-2 w-full" id="blurb" :placeholder="post.blurb" />
          </div>
          <div class="mb-6">
            <label for="slug" class="label">Slug</label><br>
            <input type="title" v-model="slug" class="input border rounded p-2 w-full" id="slug" required :placeholder="post.slug" />
          </div>
          <button
            v-if="(postId === null)"
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            @click.prevent="createItem"
          >
            Create
          </button>
          <button
            v-if="postId"
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            @click.prevent="createItem"
          >
            Update
          </button>
        </form>
      </div>
    </div>
    <div
      v-if="showModal"
      class="bg-white rounded w-2/5 mx-auto shadow p-10 absolute"
      style="left:30%; top:calc(0px + 20vh);"
    >
      <p class="my-10">Are you sure you want to delete this?</p>
      <div class="flex flex-row justify-between">
        <button class="bg-blue-500 text-white font-bold py-2 px-4 rounded inline-block" @click="toggleDelete">Nope!</button>
        <button class="bg-red-500 text-white font-bold py-2 px-4 rounded inline-block" @click="deleteItem">Yep!</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Edit',
  data () {
    return {
      inputImage: null,
      postId: null,
      title: "",
      author: "",
      post: [],
      showModal: false,
      blurb: "",
      body: "",
      slug: "",
    }
  },
  created () {
    if (!this.$store.state.signedIn && this.$store.state.currentUser.role !== "admin") {
      this.$router.replace('/');
    }else if(this.$store.state.postId){
      this.postId = this.$store.state.postId
      this.$http.plain.get(`/posts/${this.postId}`)
        .then(response => { this.post = response.data })
        .catch(error => this.setError(error, 'Something went wrong'))
      this.title = this.post.title
      this.author = this.post.author
      this.body = this.post.body
      this.blurb = this.post.blurb
      this.slug = this.post.slug
    }else {
      return
    }
  },
  methods: {
    uploadImage () {
      this.inputImage = this.$refs.inputPic.files[0];
    },
    deleteItemCheck () {
      this.toggleDelete();
    },
    deleteItem () {
      this.$http.plain.delete(`/posts/${this.postId}`)
      this.$store.state.postId = null
      window.location = "/posts";
    },
    toggleDelete () {
      this.showModal = !this.showModal;
    },
    createItem () {
      const imageparams = {
        'image': this.inputImage
      }

      let formData = new FormData()
      Object.entries(imageparams).forEach(
        ([key, value]) => formData.append(key, value)
      )

      // FIX  - unable to upload a file and update text at the same time.
      if(this.postId){
        if(this.inputImage){
          this.$http.uploadFile.patch(`/posts/${this.postId}`,
          formData)
        }

        this.$http.plain.patch(`/posts/${this.postId}`, {
          title: this.title,
          author: this.author,
          body: this.body,
          blurb: this.blurb,
          slug: this.slug
        })
      } else { //create content
        this.$http.plain.post("/posts/", {
          title: this.title,
          author: this.author,
          body: this.body,
          blurb: this.blurb,
          slug: this.slug,
        })
      }
    },
    setActive () {
      let activeSlug = this.post.slug;
      let postBlurb = this.post.blurb;

      this.$store.commit('setPostBlurb', postBlurb )

      //set this post to active
      //should create seperate modal that contains active post and post slugs
      if(this.post.active === false)
        this.$http.plain.patch(`/posts/${this.postId}`, {
          post: { active: true }
      })

      this.$store.commit('setActivePostSlug', activeSlug )
      if(this.$store.state.activePostSlug === activeSlug ){
        alert (activeSlug);
        alert ("This post is now set to active");
      }
    }
  }
}
</script>
