import Router from 'vue-router'
import Signin from '@/components/Signin'
import Signup from '@/components/Signup'
import Posts from '@/components/Posts/Posts'
import PostSlug from '@/components/Posts/_slug'
import Edit from '@/components/Posts/edit'
import Materials from '@/components/Materials/Materials'
import _Slug from '@/components/Materials/_slug'
import Editor from '@/components/Materials/editor'
import Admin from '@/components/Admin'
import Homepage from '@/components/Homepage'
import About from '@/components/About'
import Contact from '@/components/Contact'
import Privacy from '@/components/Privacy'
import Account from '@/components/Account'
import Terms from '@/components/Terms'
import ForgotPassword from '@/components/ForgotPassword'
import ResetPassword from '@/components/ResetPassword'
import UserEdit from '@/components/admin/users/Edit'
import UserList from '@/components/admin/users/List'
import Map from '@/components/Map'
import SystemOverview from '@/components/Sandragon/SystemOverview'
import SandragonRules from '@/components/Sandragon/Rules'
import AtlantisFrostAndFlood from '@/components/Sandragon/AtlantisFrostAndFlood'
import CharacterEdit from '@/components/Characters/Edit'
import Table from '@/components/Characters/Table'
import Wiki from '@/components/Wiki/index.vue'
import WikiEditor from '@/components/Wiki/ArticlesEditor'
import WikiSlug from '@/components/Wiki/_slug'
import NewGame from '@/components/GameTable/NewGame'
import Games from '@/components/GameTable/Games'
import Game from '@/components/GameTable/Game'
import GameEdit from '@/components/GameTable/edit'
import Sandbox from '@/components/Sandbox'
import Browzart from '@/components/Browzart/Browzart'

export default new Router({
  mode: 'history',
  routes: [
    {path: '/', name:'Homepage', component: Homepage},
    {path: '/homepage', redirect: { name: 'Homepage' }},
    {path: '/signin', name: 'Signin', component: Signin},
    {path: '/signup', name: 'Signup', component: Signup},
    {path: '/posts', name: 'Posts', component: Posts},
    {path: '/posts/edit/', name: 'Edit',  component: Edit },
    {path: '/posts/:slug', name: 'PostSlug', component: PostSlug },
    {path: '/content', name: 'Materials', component: Materials},
    {path: '/materials/editor/', name: 'Editor', component: Editor },
    {path: '/content/:slug', name: '_Slug', component: _Slug },
    {path: '/admin', name:'Admin', component: Admin},
    {path: '/map', name:'Map', component: Map},
    {path: '/about', name:'About', component: About},
    {path: '/contact', name:'Contact', component: Contact},
    {path: '/privacy-policy', name:'Privacy', component: Privacy},
    {path: '/account', name:'Account', component: Account},
    {path: '/terms', name:'Terms', component: Terms},
    {path: '/forgot_password', name: 'ForgotPassword', component: ForgotPassword},
    {path: '/password_resets/:token',name: 'ResetPassword',component: ResetPassword},
    {path: '/admin/users/:id',name: 'UserEdit',component: UserEdit},
    {path: '/admin/users',name: 'UserList',component: UserList},
    {path: '/sandragon/system-overview',name: 'SystemOverview',component: SystemOverview},
    {path: '/sandragon/rules',name: 'SandragonRules',component: SandragonRules},
    {path: '/atlantis-frost-and-flood',name: 'AtlantisFrostAndFlood',component: AtlantisFrostAndFlood},
    {path: '/characters/edit',name: 'CharacterEdit',component: CharacterEdit},
    {path: '/table',name: 'Table',component: Table},
    {path: '/wiki',name: 'Wiki',component: Wiki},
    {path: '/wiki/editor',name: 'WikiEditor',component: WikiEditor},
    // {path: '/wiki/:slug/editor',name: 'WikiEditor',component: WikiEditor},
    {path: '/wiki/:slug',name: 'WikiSlug',component: WikiSlug},
    {path: '/game/new',name: 'NewGame',component: NewGame},
    {path: '/games',name: 'Games',component: Games},
    {path: '/game',name: 'Game',component: Game},
    {path: '/game/edit',name: 'GameEdit',component: GameEdit},
    {path: '/sandbox',name: 'Sandbox',component: Sandbox},
    {path: '/browzart',name: 'Browzart',component: Browzart},
  ]
})
