<template>
  <div id="3dmap" class="map-container">
    <div v-if="mapSettings" class="map-controls controls-container rounded bg-sd-light-blue bshadow">
      <p class="sd-font-orange bold pt-2 text-style font150">Map Settings</p>
      <br>
      <label>Change Scene </label><br>
      <!-- All of this should be moved into a separate component that just handles map editing and is only available if user has permisions - like a GM -->
      <button @click="highlightAll" class="bg-sd-dark-blue text-white py-1 px-2 rounded map-button ">Highlight Objects</button>
      <button @click="rotateMap" class=" bg-sd-dark-blue text-white py-1 px-2 rounded map-button ">Rotate Map</button>
      <button @click="toggleSceneObjects" class=" bg-sd-dark-blue text-white py-1 px-2 rounded map-button ">Edit Objects</button>
      <button @click="toggleOrbit" class=" bg-sd-dark-blue text-white py-1 px-2 rounded  map-button">Toggle Orbit</button>
      <br><label style="border-bottom:1px solid orange">Grid</label>
      <button @click="gridToggle" class=" bg-sd-dark-blue text-white py-1 px-2 rounded  map-button"> Toggle Grid</button>
      <p class="inline font75">Grid Count:</p>
      <input class="text-black map-button mt-2" v-model="gridInput" placeholder="Gridcount" />
      <button @click="updateGrid" class="bg-sd-dark-blue text-white py-1 px-2 rounded  map-button">Update Grid Count</button>
      <br><label style="border-bottom:1px solid orange">Ambient Lighting</label>
      <p class="inline font75">Intensity:</p>
      <input class="text-black map-button" v-model="ambientLightIntensity" placeholder="between 0-1"/>
      <p class="inline font75">Color:</p>
      <input class="text-black map-button" v-model="ambientLightColor" placeholder="default: 0xffffff" />
      <vue-tailwind-color-picker v-model="colorPickerValue" @change="changedAmbientColor(colorPickerValue)" style="transform:scale(0.8); background-color:#9CA9C1; border:none;"/>
      <button @click="updateAmbientLighting" class="bg-sd-dark-blue text-white py-1 px-2 rounded  map-button">Update Lighting</button>


      <div class="map-controls rounded bg-sd-light-blue bshadow" style="color:#0F102F; z-index:2001;" v-if="viewSceneObjects" @click="toggleSceneObjects">
        <p class="sd-font-orange bold py-2 text-style font150">Scene Objects</p>
        <div>
          <span v-for="(obj) in playerObjects" :key="obj.id">
            <button @click="togglePlayerObject(obj.marker_id)" class=" bg-sd-dark-blue text-white py-1 px-2 rounded map-button">
              Player id:{{ obj.marker_id }}
            </button>
          </span>
          <span v-for="(obj) in sceneObjects" :key="obj.id">
            <button v-if="obj.objType ==='light'" @click="toggleLightObject(obj.marker_id)" class=" bg-sd-dark-blue text-white py-1 px-2 rounded map-button">
              💡 {{ obj.name || obj.marker_id }}
            </button>
            <button v-if="obj.objType ==='sprite'" @click="toggleSpriteObject(obj.marker_id)" class=" bg-sd-dark-blue text-white py-1 px-2 rounded map-button">
              🖼️ {{ obj.name || obj.marker_id }}
            </button>
             <button v-if="obj.objType ==='3dmodel'" @click="toggle3DModelObject(obj.marker_id)" class=" bg-sd-dark-blue text-white py-1 px-2 rounded map-button">
              🖼️ {{ obj.name || obj.marker_id }}
            </button>
             <button v-if="obj.objType === 'mapNote'" @click="toggleMapNote(obj.marker_id)" class=" bg-sd-dark-blue text-white py-1 px-2 rounded map-button">
              🖼️ {{ obj.name || obj.marker_id }}
            </button>
            <font-awesome-icon
              v-if="obj.objType !== 'player'"
              :icon="['fas', 'trash-can']"
              style="color:red;"
              @click="deleteObject(obj.marker_id)"
            />
          </span>
        </div>
        <button @click="addLight" class=" bg-sd-dark-blue text-white py-1 px-2 my-2 rounded map-button">Add Light</button>
        <button @click="toggleAvailableSprites" class=" bg-sd-dark-blue text-white py-1 px-2 my-2 rounded map-button">Add Sprite</button>
        <button @click="toggleAvailable3dModels" class=" bg-sd-dark-blue text-white py-1 px-2 my-2 rounded map-button">Add 3D Model</button>
        <button @click="addMapNote" class=" bg-sd-dark-blue text-white py-1 px-2 my-2 rounded map-button">Add Map Note</button>
      </div>

      <div class="map-controls rounded bg-sd-light-blue bshadow" style="color:#0F102F; z-index:2002;" v-if="viewLightObject">
        <p class="sd-font-orange bold pt-2 text-style font150">Light Settings</p>
        <p class="sd-font-orange bold pt-2 text-style font75">id {{currentLight}}
         <button
            @click="toggleLightObject()"
            class="bg-red-500 hover:bg-red-700 text-white px-2 rounded relative border small font125"
            style="display:inline-block; height:30px;">
          X
          </button>
        </p>
        <p class="inline font75">Name:</p>
        <input v-model="pointLightName" class="text-black map-button" placeholder="Name" @change="updatePointLighting"/>
        <p class="inline font75">Scale:</p>
        <input v-model="pointLightScale" class="text-black map-button" placeholder="Scale" @change="updatePointLighting"/>
        <p class="inline font75">Color:</p>
        <input v-model="pointLightColor" class="text-black map-button" placeholder="hex color" @change="updatePointLighting"/>
        <vue-tailwind-color-picker v-model="colorPickerValue" @change="changedLightColor(colorPickerValue)" :hide-swatches="true" style="transform:scale(0.8); background-color:inherit; border:none;" />
        <p class="inline font75">Decay:</p>
        <input v-model="pointLightDecay" class="text-black map-button" placeholder="default: 1" @change="updatePointLighting"/>
        <p class="inline font75">Distance:</p>
        <input v-model="pointLightDistance" class="text-black map-button" placeholder="default: 10" @change="updatePointLighting"/>
        <p class="inline font75">Power:</p>
        <input v-model="pointLightPower" class="text-black map-button" placeholder="default: 2" @change="updatePointLighting"/>
        <p class="inline font75">Intensity:</p>
        <input v-model="pointLightIntensity" class="text-black map-button" placeholder="default: 2" @change="updatePointLighting"/>
        <!-- <p class="inline">Flicker Animation:</p>
        <input class="text-black map-button" placeholder="default: on" @change="updatePointLighting"/> -->
      </div>
      <div class="map-controls rounded bg-sd-light-blue bshadow" style="color:#0F102F; z-index:2002;" v-if="viewAvailableSprites">
        <p @click="toggleAvailableSprites">Close</p>
        <ul v-if="spriteImages.length > 0" class="w-full flex justify-between">
        <li v-for="image in spriteImages" :key="image" style="text-decoration: none; width:60px;">
          <img :src="image" @click="addSprite(image)" class="preview-image rounded"  width="100%" height="auto"/>
        </li>
        </ul>
      </div>
      <div class="map-controls rounded bg-sd-light-blue bshadow" style="color:#0F102F; z-index:2002;" v-if="viewSpriteObject">
        <p class="sd-font-orange bold pt-2 text-style font150">Sprite Settings</p>
        <p class="sd-font-orange bold pt-2 text-style">id {{currentSprite}}
         <button
            @click="toggleSpriteObject()"
            class="bg-red-500 hover:bg-red-700 text-white px-2 rounded relative border small font125"
            style="display:inline-block; height:30px;">
          X
          </button>
        </p>
        <input v-model="spriteName" class="text-black map-button" placeholder="Name" @change="updateSprite"/>
        <p class="inline font75">Scale:</p>
        <input v-model="spriteObjScale" class="text-black map-button" placeholder="Scale" @change="updateSprite"/>
        <p class="inline font75">Touch Action:</p>
        <input type="checkbox" value="spriteTouchAction" class="text-black map-button" placeholder="Touch Action" @change="toggleTouchAction"/>
        <label for="checkbox">{{ spriteTouchAction }}</label>
      </div>
      <div class="map-controls rounded bg-sd-light-blue bshadow" style="color:#0F102F; z-index:2002;" v-if="viewMapNoteObject">
        <p class="sd-font-orange bold pt-2 text-style">Note Settings</p>
        <p class="sd-font-orange bold pt-2 text-style font75">id {{currentMapNote}}
         <button
            @click="toggleMapNote()"
            class="bg-red-500 hover:bg-red-700 text-white px-2 rounded relative border small font125"
            style="display:inline-block; height:30px;"
          >
          X
          </button>
        </p>
        <input v-model="mapNoteName" class="text-black map-button" placeholder="Name" @change="updateMapNote"/>
        <p class="inline font75">Scale:</p>
        <input v-model="mapNoteObjScale" class="text-black map-button" placeholder="Scale" @change="updateMapNote"/>
      </div>

      <div class="map-controls rounded bg-sd-light-blue bshadow" style="color:#0F102F; z-index:2002;" v-if="viewAvailable3dModels">
        <p @click="toggleAvailable3dModels">Close</p>
        <ul v-if="modelUrls.length > 0" class="w-full flex justify-between">
        <li v-for="url in modelUrls" :key="url" style="text-decoration: none; width:60px;">
          <img :src="url" @click="add3dModel(url)" class="preview-image rounded"  width="100%" height="auto"/>
        </li>
        </ul>
      </div>

      <div class="map-controls rounded bg-sd-light-blue bshadow" style="color:#0F102F; z-index:2002;" v-if="view3dModel">
        <p class="sd-font-orange bold pt-2 text-style font150">Model Settings</p>
        <p class="sd-font-orange bold pt-2 text-style font75">id {{currentModel}}
         <button
            @click="toggle3DModelObject()"
            class="bg-red-500 hover:bg-red-700 text-white px-2 rounded relative border small font125"
            style="display:inline-block; height:30px;">
          X
          </button>
        </p>
        <input v-model="modelName" class="text-black map-button" placeholder="Name" @change="update3dModel"/>
        <p class="inline font75">Scale:</p>
        <input v-model="modelObjScale" class="text-black map-button" placeholder="Scale" @change="update3dModel"/>
      </div>


      <div class="map-controls rounded bg-sd-light-blue bshadow" style="color:#0F102F; z-index:2002;" v-if="viewPlayerObject">
        <p class="sd-font-orange bold pt-2 text-style font150">Player Settings</p>
        <p class="sd-font-orange bold pt-2 text-style font150">Player {{currentPlayer}}
          <button
            @click="togglePlayerObject()"
            class="bg-red-500 hover:bg-red-700 text-white px-2 rounded relative border small font125"
            style="display:inline-block; height:30px;">
          X
          </button>
        </p>
        <p class="inline font75">Scale:</p>
        <input v-model="playerScaleModifier" class="text-black map-button" placeholder="1" @change="updatePlayerSettings"/>
      </div>
    </div>
    <Loading class="loader" :style= "(toggleLoading ? 'opacity:0.8' : 'opacity:0' )" />
  </div>
</template>

<script>
import { init,
         getTablePayload,
         updateWithSelectedImage,
         rotateTableGeometry,
         toggleGrid,
         updateGrid,
         toggleOrbitControls,
         getAmbientLighting,
         imageDimensions,
         objectMarkerArray,
         updateUserRole,
         highlightMarker,
         showLightHelper,
         stats
        } from '../javascript/ThreeComponents/map.js';

import Loading from './UI/Loading.vue';

if (window.location.pathname === '/game') {
  document.body.appendChild(stats.dom);
}

export default {
  name: 'Sandbox',
  components: {
    Loading
  },
  props: {
    mapSettings: {
      type: Boolean,
      required: false,
      default: true // change this back to false
    },
    gameSettings: {
      type: Boolean,
      required: false,
      default: false
    },
    currentTable: {
      type: Number,
      required: false
    },
    allPlayers: {
      type: Array,
      required: false
    },
    currentPlayerId: {
      type: Number,
      required: false
    },
  },
   data() {
    return {
      gameId: null,
      mapImages: [],
      payload: null,
      gridInput: 0,
      assetsLoaded: false,
      fetchPayload: null,
      defaultImage: {},
      userIsGM: false,
      toggleLoading: false,
      tableOrientation: null,
      gridVisible: false,
      orbitToggle: false,
      ambientLightColor: 0xffffff,
      pointLightColor: 0xffffff,
      ambientLightIntensity: 1,
      objectMarkerArray: [],
      sceneObjects: [],
      playerObjects: [],
      viewSceneObjects: false,
      viewLightObject: false,
      currentLight: null,
      viewPlayerObject: false,
      currentPlayer: null,
      playerScaleModifier: 1,
      ambientLightOptions: {},
      // defaultScene should probably be a "currentScene"
      defaultScene: null,
      defaultSceneIndex: null,
      viewAvailableSprites: false,
      spriteImages: [],
      modelUrls: [],
      viewSpriteObject: false,
      spriteObjScale: 1,
      modelObjScale: 1,
      currentSprite: null,
      currentModel: null,
      spriteName: null,
      modelName: null,
      pointLightName: null,
      pointLightScale: 1,
      editableObj: {},
      scenesArray: [],
      colorPickerValue: "#ffffff",
      highlightAllToggle: false,
      viewAvailable3dModels: false,
      pointLightDecay: 2,
      pointLightDistance: 10,
      pointLightPower: 100,
      pointLightIntensity: 100,
      view3dModel: false,
      viewMapNoteObject: false,
      currentMapNote: null,
      mapNoteName: null,
      mapNoteObjScale: null,
      noteTexture: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0pJwwIfovKAXukG8uU_-IPjoO3dOhuKeMre0yEFDcIh-ZAC-QMGNLnsqu7bRAWH0E4wk&usqp=CAU",
      spriteTouchAction: false
    }
  },
  channels: {
    GameMapChannel: {
      connected() {
        console.log("Connected to the map channel");
      },
      rejected() {},
      received(data) {
        if (data.gameId === this.gameId) {
          let updatedPlayerArray = data.content
          objectMarkerArray.forEach((marker, index) => {
            updatedPlayerArray.forEach(player => {
              if (player !== undefined && (marker.marker_id === player.marker_id)) {
                // updates the current position of the object
                marker.position.x = player.position.x
                marker.position.y = player.position.y
                marker.position.z = player.position.z
                // updates the object position on the backend
                if (player.isPlayer){
                  this.defaultScene.playerObjects[index].position.x = player.position.x
                  this.defaultScene.playerObjects[index].position.y = player.position.y
                  this.defaultScene.playerObjects[index].position.z = player.position.z
                } else {
                  index = index - this.defaultScene.playerObjects.length
                  this.defaultScene.sceneObjects[index].position.x = player.position.x
                  this.defaultScene.sceneObjects[index].position.y = player.position.y
                  this.defaultScene.sceneObjects[index].position.z = player.position.z
                }
              }
            })
          })
          this.updatePayload()
        }
      },
      disconnected() {},
    },
  },
  created() {
    if( this.currentTable ){
      this.gameId = parseInt(this.currentTable)
      this.fetchGameData();
    }
  },
  computed: {
    currentGameId () {
       return this.$store.state.gameId
    },
    generatedId () {
      return Date.now() + Math.floor(Math.random() * 1000)
    }
  },
  methods: {
    gridCount () {
      return this.gridInput
    },
    toggleTouchAction () {
      this.spriteTouchAction = !this.spriteTouchAction
      this.updateSprite();
    },
    highlightAll () {
      this.highlightAllToggle = !this.highlightAllToggle
      if (this.highlightAllToggle){
        this.sceneObjects.forEach(obj => {
          highlightMarker(obj.marker_id)
        })
        this.playerObjects.forEach(obj => {
          highlightMarker(obj.marker_id)
        })
      } else {
        highlightMarker(null)
      }
    },
    changedAmbientColor(color) {
      let newColor = color.slice(0, -2)
      this.ambientLightColor = newColor.replace('#', '0x')
    },
    changedLightColor(color) {
      let newColor = color.slice(0, -2)
      this.pointLightColor = newColor.replace('#', '0x')
      this.updatePointLighting()
    },
    updateSprite () {
      this.sceneObjects.forEach(obj => {
        if (obj.objType === "sprite" && obj.marker_id === this.currentSprite) {
          obj.scaleModifier = parseInt(this.spriteObjScale)
          obj.name = this.spriteName
          obj.touchAction = this.spriteTouchAction
        }
      })
      this.defaultScene.sceneObjects = this.sceneObjects
      this.updatePayload()
    },
    updateMapNote () {
      this.sceneObjects.forEach(obj => {
        if (obj.objType === "mapNote" && obj.marker_id === this.currentMapNote) {
          obj.scaleModifier = parseInt(this.mapNoteObjScale)
          obj.name = this.mapNoteName
        }
      })
      this.defaultScene.sceneObjects = this.sceneObjects
      this.updatePayload()
    },
    update3dModel () {
      this.sceneObjects.forEach(obj => {
        if (obj.objType === "3dmodel" && obj.marker_id === this.currentModel) {
          obj.scaleModifier = parseInt(this.modelObjScale)
          obj.name = this.modelName
        }
      })
      this.defaultScene.sceneObjects = this.sceneObjects
      this.updatePayload()
    },
    updatePointLighting () {
      //Not getting the full object here - need to fix
      this.sceneObjects.forEach(obj => {
        if (obj.objType === "light" && obj.marker_id === this.currentLight) {
          obj.lightcolor = this.pointLightColor
          obj.name = this.pointLightName
          obj.scaleModifier = parseInt(this.pointLightScale)
          obj.decay = parseInt(this.pointLightDecay)
          obj.distance = parseInt(this.pointLightDistance)
          obj.power = parseInt(this.pointLightPower)
          obj.intensity = parseInt(this.pointLightIntensity)
        }
      })
      this.defaultScene.sceneObjects = this.sceneObjects
      this.updatePayload()
    },
    updatePlayerSettings () {
      this.playerObjects.forEach(obj => {
        if (obj.marker_id === this.currentPlayer) {
          obj.scaleModifier = parseInt(this.playerScaleModifier)
        }
      })
      this.defaultScene.playerObjects = this.playerObjects
      this.updatePayload()
    },
    deleteObject (markerId) {
      // Because using index for id, goingt to get multiple objects with the same id
      this.defaultScene.sceneObjects = this.sceneObjects.filter(obj => obj.marker_id !== markerId)
      this.updatePayload()
    },
    async fetchGameData () {
      await this.$http.plain.get(`/game_tables/${this.gameId}`)
      .then(response => {
        this.gameData = response.data
        this.mapImages = Array.from(this.gameData.images_urls)
        this.spriteImages = Array.from(this.gameData.sprites_urls)
        this.modelUrls = Array.from(this.gameData.files_urls)
        this.payload = JSON.parse(this.gameData.payload)
        this.scenesArray = this.payload.scenes
        getTablePayload(this.payload)
        this.fetchPayload = JSON.parse(this.gameData.payload)
        this.defaultSceneIndex = this.payload.defaultScene
        this.defaultScene = this.payload.scenes[this.defaultSceneIndex]
        this.ambientLightOptions = this.defaultScene.ambientLightOptions
        this.ambientLightColor = this.ambientLightOptions.color
        this.ambientLightIntensity = this.ambientLightOptions.intensity
        this.defaultImage = this.defaultScene.image_url || this.gameData.image_urls[0]
        this.sceneObjects = this.defaultScene.sceneObjects
        this.playerObjects = this.defaultScene.playerObjects
        this.assetsLoaded = true
      })
      .catch(error => this.setError(error, 'Something went wrong'))

      this.checkUserRole(this.currentPlayerId)
      if (this.assetsLoaded === true) {
        this.checkForPlayerUpdates()
        init('3dmap', this.currentPlayerId)
        this.updateGrid()
        this.objectMarkerArray = objectMarkerArray
      }
    },
    checkForPlayerUpdates() {
      // let existingPlayers = []
      let characters = this.gameData.participant_characters
      if (this.playerObjects) {
        this.playerObjects.forEach((playerObject) => {
          let player = characters.filter(char => char.user_id === playerObject.marker_id);
          if (playerObject.image !== player[0].image) {
            playerObject.image = player[0].image
          }
        })
      }
      // // add check for allplayers and existing players lengths being different
      // // Not sure if this is still needed.
      //   if (existingPlayers.length === 0  ) {
      //     this.allPlayers.forEach((player) => {
      //       this.playerObjects.push({
      //         marker_id: player.user_id,
      //         image: player.image
      //         // objType: "player"
      //       })
      //     })
      //   }
      //   this.defaultScene.playerObjects = this.playerObjects
      // }
    },
    checkUserRole (id) {
      if (id === -321) {
        this.userIsGM = true;
      } else {
        this.userIsGM = false;
      }
      updateUserRole(this.userIsGM)
    },
    updateWithSelectedImage(name) {
      this.defaultScene = this.scenesArray.filter(scene => scene.name === name);
      updateWithSelectedImage(this.defaultScene.image_url)
      this.defaultScene.dimensions = imageDimensions
      this.updatePayload()
    },
    updatePayload () {
      this.toggleLoading = true;
      this.payload.scenes[this.defaultSceneIndex] = this.defaultScene
      let payload = JSON.stringify(this.payload);
      this.$http.secured.patch(`/game_tables/${this.gameId}`, {
        game_table: {
          payload
        }
      })
      setTimeout(() => {
        this.toggleLoading = false;
      }, 2000)
    },
    rotateMap () {
      this.tableOrientation = rotateTableGeometry()
      this.updatePayload()
    },
    gridToggle () {
      this.gridVisible = !this.gridVisible
      toggleGrid(this.gridVisible)
    },
    toggleSceneObjects () {
      this.viewSceneObjects = !this.viewSceneObjects
    },
    toggleLightObject (id) {
      this.viewLightObject = !this.viewLightObject
      if (id) {
        this.currentLight = id
        this.editableObj = this.sceneObjects.filter(light => light.marker_id === this.currentLight);
        this.editableObj = this.editableObj[0]
        // change this to a computed object
        this.pointLightColor = this.editableObj.lightcolor
        this.pointLightName = this.editableObj.name
        this.pointLightScale = this.editableObj.scaleModifier
        this.pointLightDecay = this.editableObj.decay
        this.pointLightDistance = this.editableObj.distance
        this.pointLightPower = this.editableObj.power
        this.pointLightIntensity = this.editableObj.intensity
      }
      showLightHelper(this.viewLightObject, this.currentLight)
    },
    toggleAvailableSprites () {
      this.viewAvailableSprites = !this.viewAvailableSprites
    },
    toggleSpriteObject (id) {
     this.viewSpriteObject = !this.viewSpriteObject
      if (id) {
        this.currentSprite = id
        this.editableObj = this.sceneObjects.filter(scene => scene.marker_id === this.currentSprite);
        this.editableObj = this.editableObj[0]
        this.spriteName = this.editableObj.name
        this.spriteObjScale = this.editableObj.scaleModifier
      }
      highlightMarker(this.currentSprite)
    },
     toggleMapNote (id) {
     this.viewMapNoteObject = !this.viewMapNoteObject
      if (id) {
        this.currentMapNote = id
        this.editableObj = this.sceneObjects.filter(scene => scene.marker_id === this.currentMapNote);
        this.editableObj = this.editableObj[0]
        this.mapNoteName = this.editableObj.name
        this.mapNoteObjScale = this.editableObj.scaleModifier
      }
      highlightMarker(this.currentMapNote)
    },
    toggleAvailable3dModels () {
      this.viewAvailable3dModels = !this.viewAvailable3dModels
    },
    toggle3DModelObject (id) {
      this.view3dModel = !this.view3dModel
      if (id) {
        this.currentModel = id
        this.editableObj = this.sceneObjects.filter(scene => scene.marker_id === this.currentModel);
        this.editableObj = this.editableObj[0]
        this.modelName = this.editableObj.name
        this.modelObjScale = this.editableObj.scaleModifier
      }
      highlightMarker(this.currentSprite)
    },
    togglePlayerObject (id) {
      this.viewPlayerObject = !this.viewPlayerObject
      if (id){
        this.currentPlayer = id
      }
      highlightMarker(this.currentPlayer)
    },
    updateGrid() {
      updateGrid(this.gridInput)
      this.defaultScene.gridCount = this.gridInput
      this.updatePayload()
    },
    toggleOrbit () {
      this.orbitToggle = !this.orbitToggle
      toggleOrbitControls(this.orbitToggle)
    },
    updateAmbientLighting () {
      // this might not need to be in computed
      this.defaultScene.ambientLightOptions = { color: this.ambientLightColor, intensity: this.ambientLightIntensity }
      getAmbientLighting(this.ambientLightColor, this.ambientLightIntensity)
      this.updatePayload()
    },
    addLight () {
      this.sceneObjects.push({objType: "light", position: {x: 0, y: 0, z: 0}, marker_id: this.generatedId})
      this.defaultScene.sceneObjects = this.sceneObjects
      this.updatePayload()
    },
    addMapNote () {
      this.sceneObjects.push({objType: "mapNote", position: {x: 0, y: 0, z: 0}, marker_id: this.generatedId, image: this.noteTexture})
      this.defaultScene.sceneObjects = this.sceneObjects
      this.updatePayload()
    },
    addSprite(image) {
      if (image) {
        this.sceneObjects.push({objType: "sprite", position: {x: 0, y: 0, z: 0}, marker_id: this.generatedId, image: image})
      } else {
        this.sceneObjects.push({objType: "mapNote", position: {x: 0, y: 0, z: 0}, marker_id: this.generatedId})
      }
      this.defaultScene.sceneObjects = this.sceneObjects
      this.updatePayload()
    },
    add3dModel(url) {
      this.sceneObjects.push({objType: "3dmodel", position: {x: 0, y: 0, z: 0}, marker_id: this.generatedId, url: url})
      this.defaultScene.sceneObjects = this.sceneObjects
      this.updatePayload()
    }
  },
  mounted() {
    // need to add an id check for this
    this.$cable.subscribe({
      channel: "GameMapChannel"
    })
  }
}

</script>

<style scoped lang="scss">


  .font75 {
    font-size: 0.75rem;
  }
  .font125 {
    font-size: 1.25rem;
  }
  .font150 {
    font-size: 1.5rem;
  }
  .map-container {
    position: relative;
    z-index: 2;

    .controls-container {
      color:#0F102F;
      z-index: 2000;
    }

    .text-style {
      color:#0F102F;
      font-weight: 500;
      text-align:center;
    }

    .preview-image:hover {
      transform: scale(1.2);
    }

    .map-controls {
      // opacity: 0.9;
      padding: 4px;
      position: fixed;
      width: 260px;
      color: white;
      right: 10px;
      bottom: 100px;
      height: 400px;
      display: flex;
      flex-direction: column;
      overflow-y: scroll;
    }

    .buttonContainer {
      display:flex;
      flex-direction:row;
      justify-content: space-around;
    }

    .map-button {
      transform: scale(0.8);
      width: 200px;
      text-align:center;
      margin: 0px auto;
    }

    .loader {
      position: fixed;
      z-index: 20;
      transform: scale(20%);
      top: 40px !important;
      left: 45%;
      transition: opacity 750ms;
    }
    .bshadow {
      box-shadow: 39px 10px 5px 0px rgba(0,0,0,0.75);
    }
  }

</style>
