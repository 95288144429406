<template>
  <div>
    <p class="text-center py-4">Account Edit</p>
    <form class="md:w-2/5 mx-auto">
      <div class="px-8">
        <p class="mt-4 py-4">{{ userName }}</p>
        <img :src="user.image_url" class="w-40 h-40 ml-2 bg-gray-900 bg-origin-padding rounded-full inline-block ml-2 mr-1"/>
        <label class="block text-gray-700 text-sm font-bold mb-2 p-2" for="image">User Image</label>
        <input class="mb-4 sm-w:4/5 md:w-3/5" type="file" name="image" ref="inputFile" @change=uploadFile()>
        <br>

        <button
          class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          @click.prevent=createItem()
        >
          Update
        </button>
        <br><br><br>

        <h3 class="block font-bold" style="font-size:1.5rem;">
          Game Options
        </h3>
        <p
          v-for="character in userCharacters"
          :key="character.id"
          @click="routeTo(character.id)"
          class="bg-purple-500 hover:bg-purple-700 text-white py-2 px-4 rounded w-max inline-block mr-2 my-2"
        >
          {{ parseName(character.payload) }}
        </p>
        <p
          @click="routeTo(0)"
          class="bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded w-max block mr-2 my-2"
        >
          Create Character
        </p>
        <a v-if="userCharacters.length > 0" href="/games" >
        <p
          class="bg-green-500 hover:bg-green-700 text-white py-2 px-4 rounded w-max block mr-2 my-2"
        >
          Create A Game
        </p>
        </a>
        <p
          v-else
          class="bg-green-500 hover:bg-green-700 text-white py-2 px-4 rounded w-max block mr-2 my-2"
        >
          ^ Create a character before creating a game.
        </p>
        <br>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'Account',
  data () {
    return {
      userPicture: this.$store.state.currentUserImage,
      userName: this.$store.state.currentUser.username,
      userImage: null,
      user: [],
      userId: this.$store.getters.currentUserId,
      userCharacters: [],
      editId: null
    }
  },
  created (){
    this.checkSignedIn();
    this.getPlayerCharacters();
  },
  methods: {
    parseName(payload) {
      return JSON.parse(payload).name;
    },
    checkSignedIn() {
    if (!this.signedIn()){
      this.$router.replace('/');
    } else if (this.userId) {
      this.$http.plain.get(`/users/${this.userId}`)
        .then(response => {
          this.user = response.data
          if (this.user.image !== this.userPicture) {
            this.userPicture = this.user.image_url
            this.$store.commit('setCurrentUserImage', this.user.image_url)
          }
        })
        .catch(error => this.setError(error, 'Something went wrong'))
       } else {
        return false;
      }
    },
    getPlayerCharacters() {
      if (this.signedIn()){
        this.$http.plain.get(`/users/${this.userId}/characters`)
          .then(response => {
            if (response.data.length !== 0) {
              this.editId = response.data.id
              this.sortCharacters(response.data)
            }
          })
        .catch(error => this.setError(error, 'Something went wrong'))
      }
    },
    routeTo(id) {
      this.$store.commit('setCharacterId', id)
      window.location = "/characters/edit"
    },
    sortCharacters (data) {
      data.forEach(char => this.userCharacters.push(char));
    },
    uploadFile () {
      this.userPicture = this.$refs.inputFile.files[0];
    },
    createItem: function() {
      const params = {
        'image': this.userPicture
      }
      let formData = new FormData()
      Object.entries(params).forEach(
        ([key, value]) => formData.append(key, value)
      )
      if (this.userPicture) {
        this.$http.uploadFile.patch(`/users/${this.userId}`, formData)
      }
    },
    setError (error, text) {
      this.error = (
          error.response &&
          error.response.data &&
          error.response.data.error) || text
    },
    signedIn () {
      return this.$store.state.signedIn
    },
    updateUser () {
      this.$http.plain.patch(`/users/${this.userId}`, {
        user: {
          userName: this.userName
        }
      })
    }
  }
}
</script>

