<template>
  <div>
    <div v-if="articles.length < 1" >
      <Loading />
    </div>
    <ul
      v-else
      class="list-reset my-12"
    >
      <li
        class="cursor-pointer"
        v-for="article in articles"
        :key="article.id"
        :article="article"
      >
        <p class="py-2" @click="viewContent(article.label)">
          {{ article.label }}
        </p>
        <hr>
      </li>
    </ul>
    <div class="relative">
      <button
        v-if="isAdmin()"
        class="bg-transprent text-sm hover:bg-blue-400 text-blue hover:text-white no-underline font-bold py-2 px-4 rounded border border-blue-500"
        @click="addArticle()"
        style="left:100%;"
      >
        Add Article
      </button>
    </div>
  </div>

</template>

<script>
  import Loading from '../UI/Loading.vue'

  export default {
    name: 'Articles',
    components: {
      Loading
    },
    data () {
      return {
        articles: [],
        error: ''
      }
    },
    created () {
      this.$http.secured.get('/articles')
        .then(response => { this.articles = response.data})
        .catch(error => this.setError(error, 'Something went wrong'))
    },
    methods: {
      viewContent(slug) {
        slug = slug.replaceAll(' ', '-')
        this.routeTo("/wiki/" + slug);
      },
      setError (error, text) {
        this.error = (error.response && error.response.data && error.response.data.error) || text
      },
      isAdmin() {
        return this.$store.getters.isAdmin;
      },
      addArticle(){
        this.$store.commit('setArticleId', null)
        this.routeTo("wiki/editor");
      },
      routeTo(route){
        window.location = route;
      }
    }
  }
</script>

<style scoped>
  .mason-title {
    color: white;
  }

  .mason-text {
    opacity: 0;
  }

  .break-inside:hover > .text-center > .mason-text {
    color: white;
    opacity: 1 !important;
  }

  .break-inside:hover > .mason-image {
    filter: brightness(30%);
  }

  .loading {
    background-color: #0F102F;
    color: #ED7126;
  }
</style>
