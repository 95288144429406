
<template>
  <div class="w-full">
    <div class="w-1/6 p-6" style="display:inline-block; position:fixed; z-index: 100; background-color: white; overflow:scroll;">
      <button
        v-if="isAdmin()"
        class=" bg-transprent text-sm hover:bg-blue-400 text-blue hover:text-white no-underline font-bold py-2 px-4 rounded border border-blue-500 absolute "
        @click="routeTo('')"
      >
        Back
      </button>
      <Articles style="font-size: 80%;" />
    </div>
    <div class="w-5/6" style="display:inline-block; overflow:scoll; height: max-content; overflow:scroll;">
      <div
        v-for="article in articles"
        :key="article.id"
        class="items-center"
        style="overflow:scoll;"
        :article="article">
        <!-- This need to be redone - we can't have the entire wiki loading just to see one article -->
        <div class="" v-if="article.label === cleanedSlug(slug)" style="top: 0%; padding: 3rem 4rem; padding-left: 25%; overflow:scoll; position: relative;">
          <button
            v-if="isAdmin()"
            class=" bg-transprent text-sm hover:bg-blue-400 text-blue hover:text-white no-underline font-bold py-2 px-4 rounded border border-blue-500 absolute "
            style="left:90%;"
            @click="routeTo('editor')"
          >
            Edit
          </button>
          <p class="relative mt-8 semibold text-3xl italics inline-block italic" style="font-family:'Linux Libertine','Georgia','Times',serif !important;">
            {{article.label}}
          </p>
          <hr>
          <div class="w-full mt-4 pb-4">
            <p style="font-family: 'Linux Libertine','Georgia','Times',serif !important;" v-html="article.body" />
          </div>
          <p class="text-xs inline-block" style="font-family: 'Linux Libertine','Georgia','Times',serif !important;">Authored by: {{article.author}} </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//this is not good for SEO reasons and should instead use system that associates the id with a slug
import Articles from './Articles.vue'
export default {
  components: {
    Articles
  },
  data () {
    return {
     articleId: null,
     articles: [],
     article: [],
     slug: "",
     reroute: false
    }
  },
  async created () {
    await this.$http.secured.get("/articles")
      .then(response => { this.articles = response.data })
      .catch(error => this.setError(error, 'Something went wrong'))

    this.checkSlug()
  },
  methods: {
    cleanedSlug (slug) {
      return  slug.replaceAll('-', ' ')
    },
    isAdmin () {
      return this.$store.getters.isAdmin;
    },
    routeTo (location){
      window.location = "/wiki/" + location;
    },
    isSignedIn () {
      return this.$store.state.signedIn
    },
    checkSlug () {
      // this isnt great - need to redo
      const origin = window.location.origin;
      if (origin === "http://localhost:8080") {
        this.slug = window.location.href.slice(27);
      } else if (origin === "https://www.sandragon.io") {
        //this needs fixed fix
        this.slug = window.location.href.slice(30);
      } else if (origin === "https://sandragon-front-ucexa.ondigitalocean.app") {
        this.slug = window.location.href.slice(54);
      }

      let match = false;
      this.articles.forEach(article =>{
        const slug = (article.label).replaceAll(' ', '-')
        // alert(slug)
        if (slug === this.slug ) {
          //set the  article id to whatever the content is set to at the momonet
          this.$store.commit('setArticleId', article.id)
          match = true;
          return;
        }
      });
      if (match === false) {
        window.location = "/wiki";
      }
    }
  }
}
</script>
