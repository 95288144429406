import Vue from 'vue/dist/vue.js';
import App from './App.vue'
import router from './router.js';
import VueAxios from 'vue-axios'
import { securedAxiosInstance, plainAxiosInstance, uploadFileAxiosInstance} from './backend/axios'
import Router from 'vue-router'
import Vuex from 'vuex'
import { store } from './store'
import './assets/tailwind.css'
import './assets/css/sandragon.css'
import VueCarousel from 'vue-carousel'
import vueDebounce from 'vue-debounce'
import "vue-trix"
import ActionCableVue from "actioncable-vue";
import VueTailwindColorPicker from 'vue-tailwind-color-picker'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
/* import specific icons */
import
  {
    faMap,
    faMessage,
    faScroll,
    faMaximize,
    faMinimize,
    faGear,
    faIdBadge,
    faMusic,
    faVolumeXmark,
    faTrashCan
  } from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(faMap, faMessage, faScroll, faMaximize, faMinimize, faGear, faIdBadge, faMusic, faVolumeXmark, faTrashCan)

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)


let connectionUrl = "";
const origin = window.location.origin;
if (origin === "http://localhost:8080") {
  connectionUrl = 'ws://localhost:3000/cable'
} else if (origin === "https://sandragon-front-ucexa.ondigitalocean.app" || origin === "https://www.sandragon.io") {
  connectionUrl = 'wss://dolphin-app-hbomq.ondigitalocean.app/cable'
}


Vue.use(ActionCableVue, {
  debug: true,
  debugLevel: "error",
  connectionUrl: connectionUrl,
  connectImmediately: true,
});

Vue.use(vueDebounce, {
  defaultTime: '1s',
  listenTo: ['input', 'keyup']
})

Vue.use(VueTailwindColorPicker)
Vue.use(Router);
Vue.use(Vuex);
Vue.use(VueCarousel);

Vue.config.productionTip = false
Vue.use(VueAxios, {
  secured: securedAxiosInstance,
  plain: plainAxiosInstance,
  uploadFile: uploadFileAxiosInstance
})

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  securedAxiosInstance,
  plainAxiosInstance,
  uploadFileAxiosInstance,
  components: { App },
  template: '<App/>'
})
