<template>
  <div class="px-20">
    <Articles />
  </div>

</template>

<script>
  import Articles from './Articles.vue'

  export default {
    name: 'index',
    components: {
      Articles
    },
  }
</script>

<style scoped>

</style>
