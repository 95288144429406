
// GameTable.participantCharacters is giving the wrong data. Will need to fix
// The GM should not have a player token on the board
// The player should be able to choose what character is in the game

<template>
  <div class="items-center text-center relative" :class="{ dimbackground: showModal }" >
    <div class="bg-sd-dark-blue py-20">
      <p
        class="mx-auto py-2 text-green-500 mb-6" style="font-size:2rem;">
        Game Editor
      </p>
      <button
        v-if="gameId"
        class="bg-red-500 text-white font-bold py-2 px-4 rounded mx-2"
        @click.prevent="deleteItemCheck"
      >
        Delete
      </button>

      <div class="mx-auto w-4/5 text-center p-4">
        <form class="bg-white px-8 pt-6 pb-8 mb-4 shadow rounded" style="background-color: #eeeeee;">
          <span class="id-dot flex flex-start">
            <p style="position:relative; top:-3px; left:3px;"> {{gameId}} </p>
          </span>
          <p
            v-if="gameId"
            class="bg-blue-200 rounded w-2/5 mx-auto text-blue-500 mb-12"
            style="font-size:2rem;"
          >
            <span class="mb-6">
              <input
                type="name"
                v-model="name"
                class="input rounded w-full text-center"
                placeholder="Add new name"
                id="name"
                ref="name"
                v-debounce:3s="updateGameTable"
              >
            </span>
          </p>

          <p
            v-else
            class="bg-blue-200 rounded w-2/5 mx-auto py-2 text-blue-500 mb-12"
            style="font-size:2rem;"
          >
            <span class="mb-6">
              <input
                type="name"
                v-model="name"
                class="input border rounded w-full"
                id="name" ref="name"
                placeholder="New Game Title"
                v-debounce:3s="createNewGameTable"
              >
            </span>
          </p>
          <br>
          <div class="flex flex-row justify-between">
            <label class="label bold">Scene Editor</label>
            <button
              class="bg-blue-500 hover:bg-blue-700 text-white font-bold w-1/10 py-2 px-2 rounded relative border"
              @click.prevent="addScene()"
              style="display:inline-block;right:10px;">
                 Scene +
            </button>
          </div>
          <br>


          <div v-for="(scene, index) in scenesArray" :key="scene.id" class="mb-6 scene-editor rounded p-3">
            <div class="flex flex-row w-full relative" :id="scene.id" >
              <p class="absolute" style="font-size: 0.55rem; top: -11px;">
                {{scenesArray[index].dimensions[0]}} x {{ scenesArray[index].dimensions[1] }}
              </p>
              <button @click.prevent="updateSceneImage(index)">
                <img
                  v-if="scenesArray[index].image_url"
                  :id="`image_${index}`"
                  :src="scenesArray[index].image_url"
                  class="m-2 inline  rounded"
                  style="width:100px; height: 100px;"
                  ref="`sceneImage${index}`"
                />
                <div v-else
                  class="rounded"
                  style="border:2px solid purple; width:100px; height: 100px;"
                >
                  <p class="text-center py-6 " style="font-size: 0.75rem;">Click to <br>Add Image</p>
                </div>
              </button>
              <div class="w-full px-2">
                <div class="flex flex-start  ">
                  <input type="text" v-model="scenesArray[index].name" class="input rounded w-11/12 p-1 border mx-1" :id="`name${scene.id}`" ref="name" placeholder="Name" v-debounce:2s="updatePayload">
                </div>
                <textarea v-model="scenesArray[index].notes" class="input border rounded w-11/12 l p-1 mx-1 text-xs flex flex-start" :id="`notes${id}`" ref="name" placeholder="Notes" v-debounce:2s="updatePayload"></textarea>
                <!-- <input type="text" v-model="scenesArray[index].image_url" class="input border rounded w-full l p-1 text-xs flex flex-start" :id="`image${id}`" ref="name" placeholder="image Url" v-debounce:2s="updatePayload"> -->
              </div>
              <button
                class="bg-green-500 hover:bg-green-700 text-white font-bold w-1/10 py-2 px-2 rounded relative border"
                style="display:inline-block;right:10px; height:40px;"
                @click.prevent="editScene(index)"
              >
                Edit
              </button>
              <button
                v-if="scenesArray.length > 1"
                @click.prevent="deleteScene(scene, index)"
                class="bg-red-500 hover:bg-red-700 text-white font-bold w-1/10 py-2 px-2 rounded relative border"
                style="display:inline-block;right:10px; height:40px;"
              >
                X
              </button>
            </div>
          </div>
          <br>

          <div class="orange-border rounded">
            <p class="text-lg"> Asset Library </p>
            <div v-if="uploadPercentage">
              <progress :value="uploadPercentage" max="100"></progress>
              <div>{{ uploadPercentage }}%</div>
            </div>
            <br>

            <div v-if="devEnv">
              <label class="block text-gray-700 text-sm font-bold mb-2 p-2" for="image">Map Images</label>
              <span v-for="(gameImagesUrl, index) in gameImagesUrls" :key="gameImagesUrl">
                <img
                  :src="gameImagesUrl"
                  class="m-2 inline rounded map-preview "
                  :id="`map_${index}`"
                />
              </span>
              <span style="font-size:.5rem;">{{currentImageUrl}}</span>
              <!-- <input class="mb-4 flex flex-start" type="file" name="image" ref="inputPic" @change="updateMap(index)" multiple> <br> -->
              <button class="button small" @click.prevent="deleteImage(index)">Delete</button>
            </div>

            <input type="file" name="image" ref="inputPic" @change="uploadFile('map')" multiple style="pointer-events:none; height:0; opacity:0;"> <br>


            <hr>
            <div>
              <label class="block text-gray-700 text-sm font-bold mb-2 p-2" for="image">Sprites and Textures</label>
              <span v-for="(gameSpritesUrl, index) in gameSpritesUrls" :key="gameSpritesUrl">
                <img
                  :src="gameSpritesUrl"
                  class="m-2 inline sprite-preview rounded mx-2 "
                  :id="`map_${index}`"
                  style="width:30px; height: 30px;"
                />
              </span>
            </div>
            <input class="mb-4 flex flex-start" type="file" name="sprite" ref="inputSprite" @change="uploadFile('sprite')" multiple> <br> <br>
            <hr>
            <div>
              <label class="block text-gray-700 text-sm font-bold mb-2 p-2" for="file">3D Models</label>
              <p> {{gameFilesUrls.length}} </p>
              <span v-for="(gameFilesUrl, index) in gameFilesUrls" :key="gameFilesUrl" class="flex flex-row">
                <three-window :modelUrl="gameFilesUrl" class="m-2 inline rounded " />
                <p :id="`file_${index}`" class="" style="font-size:0.5rem; "> {{ gameFilesUrl }} </p>
                <button class="button small" @click.prevent="deleteFile(index)">Delete</button>
              </span>
              <input class="mb-4 flex flex-start" type="file" name="file" ref="inputFile" @change="uploadFile('model')" multiple>
            </div>
          </div>


          <div class="orange-border rounded mt-4">
            <label class="label">Players</label><br>
            <div class="player-container">
              <span
                v-for="(player, index) in players"
                class="flex flex-start inline border p-2 my-2 rounded"
                style="border-color:black; background-color:white;" :key="player"
              >
                <img
                  :src="`https://avatars.dicebear.com/api/avataaars/${player}.svg`"
                  class="m-2 inline rounded"
                  :id="`image_${index}`"
                  style="width:40px; height: 40px;"
                />
                <div class="w-full relative flex flex-start">
                  <p class="pt-4">
                    {{ player }}
                  </p>
                  <button
                    @click.prevent="deletePlayer(player)"
                    class="text-white rounded mx-2 p-2 bg-red-500 inline-block absolute"
                    style="font-size:.75rem; right:0; top:10px;"
                  >
                    X
                  </button>
                </div>
              </span>
            </div>
            <div class="mb-6">
              <input id="player-input" class="input border rounded p-2 w-4/5" ref="players">
              <button
                class="bg-blue-500 hover:bg-blue-700 text-white font-bold w-1/5 py-2 px-2 rounded relative border"
                @click.prevent="addPlayer"
                style="display:inline-block; right:10px;"
              >
                Add Player
              </button>
            </div>
          </div>
          <br>
        </form>
      </div>
    </div>
    <Loading class="loader" :style= "(toggleAnimation ? 'opacity:0.8' : 'opacity:0' )" />
    <div
      v-if="lastSavedTime"
      class="bg-green-500 text-white font-bold py-2 px-4 rounded mx-2"
      style="position:fixed; left:89%; bottom:20px; z-index:10; font-size:0.65rem;"
    >
      <p class="text-center">Last Saved: {{lastSavedTime}}</p>
    </div>
    <div v-if="showModal" class="bg-white rounded w-2/5 mx-auto shadow p-10 absolute" style="left:30%; top:calc(0px + 20vh);">
      <p class="my-10">Are you sure you want to delete this?</p>
      <div class="flex flex-row justify-between">
        <button
          class="bg-blue-500 text-white font-bold py-2 px-4 rounded inline-block"
          @click="toggleDelete"
        >
          Nope!
        </button>
        <button
          class="bg-red-500 text-white font-bold py-2 px-4 rounded inline-block"
          @click="deleteItem"
        >
          Yep!
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import threeWindow from './modelPreview.vue'
import Loading from './../UI/Loading.vue';

export default {
  name: 'GameEditor',
  components: {
    threeWindow,
    Loading
  },
  data () {
    return {
      inputFile: [],
      name: "",
      players: [],
      game: {},
      userId: this.$store.getters.currentUserId,
      gameId: this.$store.state.gameId,
      imageCount: 0,
      playerData: null,
      playerRole: "",
      gameImagesUrls: [],
      gameFilesUrls: [],
      gameSpritesUrls: [],
      toggleAnimation: false,
      scenesArray: [],
      defaultSceneImage: "",
      sceneIndex: 0,
      scene: {},
      scenes: null,
      currentImageUrl:"",
      gameTablePayload: {},
      allPlayers: [],
      defaultScene: 0,
      payloadPlayers: [],
      uploadPercentage: null,
      responseData: null,
      lastSavedTime: null,
      devEnv: false,
      showModal: false
    }
  },
  mounted() {
    if (window.location.host === "localhost:8080") {
      this.devEnv = true;
    }
    if (!this.$store.state.signedIn) {
      this.$router.replace('/');
    }else {
      this.fetchCurrentPlayerFromGameTables();
    }
  },
  watch: {
    lastSavedTime: function (newValue, oldValue) {
     if (newValue !== oldValue) {
       return newValue;
     }
    }
  },
  methods: {
    updateSceneImage (index) {
      this.sceneIndex = index;
      this.$refs.inputPic.click();
    },
    gotoMap () {
      setTimeout(() => {
        this.$router.replace('/game');
      }, 2000)
    },
    getImageWidth(imgSrc){
      let img = new Image();
      img.src = imgSrc;
      let imageDimensions = [img.naturalWidth, img.naturalHeight]
      this.scenesArray[this.sceneIndex].dimensions = imageDimensions
    },
    editScene (index) {
      let image = this.scenesArray[index].image_url
      let img = new Image();
      img.src = image;
      let imageDimensions = [img.naturalWidth, img.naturalHeight]
      this.scenesArray[index].dimensions = imageDimensions
      this.defaultScene = index
      this.updatePayload()
      this.gotoMap()
    },
    async addScene () {
      if (!this.scenesArray) { this.scenesArray = [] }
      let sceneId = this.scenesArray.length || 0
      sceneId = sceneId + 1
      let players = []
      this.allPlayers.forEach(player => {
        players.push({
          marker_id: player.user_id,
          image: player.image,
          position: {x:0,y:0,z:0},
          name: player.payload.name,
          email: player.email,
          scaleModifier: 1
        })
      })

      this.scenesArray.push({
        id: sceneId,
        name: `Scene Name ${sceneId}`,
        image_url: "",
        dimensions: [0,0],
        options: {
          rotation: "",
          cameraStyle: ""
        },
        sceneObjects: [],
        playerObjects: players,
        gridCount: 100,
        ambientLightOptions: {},
        active: false,
        notes: ""
      })

      this.gameTablePayload.scenes = this.scenesArray;
      await this.updatePayload();
      await this.updateGameTable();
    },
    async fetchCurrentPlayerFromGameTables () {
      //Fetch the player data, get the role, and determine if current user is GM for any given GameTable
      if (this.gameId) {
        await this.$http.plain.get(`/game_tables/${this.gameId}/player`)
        .then(response => {
          this.playerData = response.data[0];
        })
        .catch(error => this.setError(error, 'Something went wrong'))

        if (this.playerData) {
          this.playerRole = this.playerData.role
          if (this.playerRole === "gm" && this.gameId && this.userId){
            this.fetchGame();
          }else {
            this.newGame()
          }
        }
      }
    },
    updateScenePayload() {
      const index = this.sceneIndex;
      const imgSrc = this.gameImagesUrls[index];
      this.getImageWidth(imgSrc);
      this.currentImageUrl = imgSrc;
      this.toggleAnimation = true;
      this.scenesArray[index].image_url = this.gameImagesUrls[index];
      this.gameTablePayload.scenes = this.scenesArray;
      let payload = JSON.stringify(this.gameTablePayload);

      this.$http.secured.patch(`/game_tables/${this.gameId}`, {
        game_table: {
          payload
        }
      });
      setTimeout(() => {
        this.toggleAnimation = false;
        this.lastSavedTime = new Date().toLocaleTimeString();
      }, 2000)
    },
    updatePayload() {
      // const index = this.sceneIndex;
      // this.defaultSceneImage = this.scenesArray[index].image_url
      this.toggleAnimation = true;
      this.currentImageUrl = this.gameImagesUrls[this.sceneIndex]
      this.gameTablePayload.gameId = this.gameId
      this.gameTablePayload.defaultScene = this.defaultScene || 0
      this.gameTablePayload.scenes = this.scenesArray
      let payload = JSON.stringify(this.gameTablePayload)
      this.$http.secured.patch(`/game_tables/${this.gameId}`, {
        game_table: {
          payload
        }
      })
      setTimeout(() => {
        this.toggleAnimation = false;
        this.lastSavedTime = new Date().toLocaleTimeString();
      }, 2000)
    },
    deleteScene (scene, index) {
      let removedScene = scene
      this.scenesArray = this.scenesArray.filter(scene => scene.name !== removedScene.name)
      this.deleteMap(index);
      this.updatePayload()
    },
    deletePlayer (player) {
      let removedPlayer = player
      this.updatePlayerPayload(player, "remove")
      this.players = this.players.filter(player => player !== removedPlayer)
      this.allPlayers = this.allPlayers.filter(player => player.email !== removedPlayer)
      this.updateGameTable()
    },
    addPlayer (value) {
      // need to update the player payload
      // doesnt seem to be happening
      let player = this.$refs.players.value || value
      if (player && player !== "" ) {
        this.players.push(player)
        this.updateGameTable();
        this.$refs.players.value = ""
        this.updatePlayerPayload(player, "add")
      } else {
        alert("no player added");
      }
    },
    async updatePlayerPayload (player, action) {
      let responseStatus = null;
      await this.$http.plain.get(`/game_tables/${this.gameId}`)
        .then(response => {
          responseStatus = response.status
          this.game  = response.data
          this.allPlayers = this.game.participant_characters
        })
        .catch(error =>  {
          this.setError(error, 'Something went wrong')
        })

      if (responseStatus === 200) {
        let updatedPlayer = player;
        this.payloadPlayers = this.allPlayers.filter(player => player.email === updatedPlayer)
        this.scenesArray.forEach(scene => {
          let scenePlayers = scene.playerObjects
          let scenePlayerEmails = scenePlayers.map(player => player.email)
          scenePlayerEmails = scenePlayerEmails.flat()

          if (action === "remove") {
            scene.playerObjects = scene.playerObjects.filter(player => player.email !== updatedPlayer)
          } else if (action === "add") {
            this.payloadPlayers.filter(player => !scenePlayerEmails.includes(player.email))
            this.payloadPlayers.forEach(player => {
              scene.playerObjects.push({
                marker_id: player.user_id,
                image: player.image,
                position: {x:0,y:0,z:0},
                name: player.payload.name,
                email: player.email,
                scaleModifier: 1
              })
            })
          }
        })
      }
      this.updatePayload()
    },
    newGame () {
      this.name = "New Game"
      this.gameTablePayload = {
        defaultScene: 0,
        scenes: this.addScene()
      }
    },
    async fetchGame () {
      await this.$http.plain.get(`/game_tables/${this.gameId}`)
        .then(response => {
          this.responseData = response.data
        })
        .catch(error => this.setError(error, 'Something went wrong'))
      if (this.responseData) {
        this.game = this.responseData
        if (this.game) {
          this.name = this.game.name
          this.players = this.game.player_users.flat()
          this.allPlayers = this.game.participant_characters
          this.gameImagesUrls = Array.from(this.game.images_urls || [])
          this.gameFilesUrls = Array.from(this.game.files_urls)
          this.gameSpritesUrls = Array.from(this.game.sprites_urls)
          let payload = this.game.payload
          this.gameTablePayload = JSON.parse(payload)
          this.scenesArray = this.gameTablePayload.scenes || [];

          this.scenesArray.forEach((scene, index) => {
            if (!scene.image_url || scene.image_url !== this.gameImagesUrls[index]) {
              scene.image_url = this.gameImagesUrls[index]
            }
            this.updatePayload()
          })
        }
      }
    },
    // async uploadFile(type) {
    //   let fileparams = {}
    //   let formData = new FormData()
    //   this.toggleAnimation = true

    //   if (type === "map") {
    //     this.inputFile = this.$refs.inputPic.files[0]
    //     fileparams = {'images': this.inputFile}
    //   } else if (type === "sprite") {
    //     this.inputFile = this.$refs.inputSprite.files[0];
    //     fileparams = {'sprites': this.inputFile }
    //   } else if (type === "model") {
    //     this.inputFile = this.$refs.inputFile.files[0];
    //     fileparams = {'files': this.inputFile}
    //   }

    //   Object.entries(fileparams).forEach(
    //     ([key, value]) => formData.append(key, value)
    //   )

    //   this.$http.uploadFile.patch(`/game_tables/${this.gameId}`, formData,{
    //     onUploadProgress: progressEvent => {
    //       this.uploadPercentage = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total))
    //     }
    //   })
    //   .then(() => {
    //     console.log("file uploaded successfully")
    //     this.uploadPercentage = null;
    //     this.getNewAssets()
    //   })
    //   .catch(() => {
    //     console.log("file upload failed")
    //     this.uploadPercentage = null;
    //     this.getNewAssets()
    //   });
    //   this.toggleAnimation = false;
    // },
    async uploadFile(type) {
      let fileparams = {}
      let formData = new FormData()
      this.toggleAnimation = true

      if (type === "map") {
        this.inputFile = this.$refs.inputPic.files[0]
        fileparams = {'images': this.inputFile}
      } else if (type === "sprite") {
        this.inputFile = this.$refs.inputSprite.files[0];
        fileparams = {'sprites': this.inputFile }
      } else if (type === "model") {
        this.inputFile = this.$refs.inputFile.files[0];
        fileparams = {'files': this.inputFile}
      }

      Object.entries(fileparams).forEach(
        ([key, value]) => formData.append(key, value)
      )
      try {
        const response = await this.$http.uploadFile.patch(`/game_tables/${this.gameId}`, formData,{
          onUploadProgress: progressEvent => {
            this.uploadPercentage = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total))
          }
        })
        if (response && response === 200) {
          console.log(response)
          console.log("file uploaded successfully")
          this.getNewAssets()
        } else {
          console.log("file upload failed")
        }
      } catch (error) {
        console.error(error);
        console.error('Something went wrong');
      }

      this.uploadPercentage = null;
    },
    updateSceneIndex(index) {
      console.log("updating scene index", index)
      this.$http.secured.post(`/game_tables/${this.gameId}/update_scene_images`, { index: index })
      .then(response => {
        if (response.status === 200) {
          console.log("scenes updated")
        }
      })
      .catch(error => {
        console.error(error);
      });
      this.getNewAssets()

    },
    async getNewAssets() {
      await this.fetchGame();
      this.updateGameTable();
      this.updateScenePayload();
    },
    deleteItemCheck () {
      this.toggleDelete();
    },
    deleteFile(index) {
      this.toggleAnimation = true;
      this.$http.secured.post(`/game_tables/${this.gameId}/remove_file`, { index: index })
      .then(response => {
        if (response.status === 200) {
          console.log("file deleted")
        }
      })
      .catch(error => {
        console.error(error);
      });
      this.toggleAnimation = false;
      this.$router.go() // this is a hack - update to be reactive
    },
    deleteMap(index) {
      this.toggleAnimation = true;
      this.$http.secured.post(`/game_tables/${this.gameId}/remove_map`, { index: index })
      .then(response => {
        if (response.status === 200) {
          console.log("map deleted")
        }
      })
      .catch(error => {
        console.error(error);
      });
      this.toggleAnimation = false;
      this.$router.go() // this is a hack - update to be reactive
    },
    deleteItem() {
      this.$http.plain.delete(`/game_tables/${this.gameId}`)
      this.$store.state.gameId = null
      window.location = "/games";
    },
    toggleDelete() {
      this.showModal = !this.showModal;
    },
    updateGameTable() {
      this.toggleAnimation = true;
      this.$http.secured.patch(`/game_tables/${this.gameId}`, {
        name: this.name,
        player_users: this.players,
        scene_images: this.gameImagesUrls
      })

      setTimeout(() => {
        this.toggleAnimation = false;
        this.lastSavedTime = new Date().toLocaleTimeString();
      }, 2000)

    },
    createNewGameTable () {
      this.toggleAnimation = true;
      this.$http.secured.post(`/game_tables`, {
        name: this.name,
        player_users: [this.$store.state.currentUser.email],
        gm_users: [this.$store.state.currentUser.email],
        user_id: this.$store.state.currentUser.id
      })
      .then(response => {
        this.$store.commit('setGameId', response.data.id)
        this.gameId = response.data.id
        this.addPlayer(this.$store.state.currentUser.email);
        this.toggleAnimation = false;
        this.lastSavedTime = new Date().toLocaleTimeString();
      })
      .catch(error => {
        this.setError(error, 'Something went wrong')
        this.toggleAnimation = false;
      })
    },
  }
}
</script>

<style scoped lang="scss">
  .loader {
    position: fixed;
    z-index: 20;
    transform: scale(20%);
    top: 40px !important;
    left: 45%;
    transition: opacity 750ms;
  }

  .id-dot {
    color: white;
    font-size: 1rem;
    background: #F17104;
    padding: 2px;
    text-align: center;
    border-radius: 50%;
    width: 20px;
    height: 20px;
  }
  .map-preview {
    width:60px;
    height: 60px;
    border: 1px solid #F17104;
    background: white;
  }
  .map-preview:hover {
    transform: scale(400%);
    border: 1px solid #F17104;
    background: white;
  }
  .sprite-preview {
    padding: 1px;
    border: 1px solid #F17104;
    background: white;
  }
  .sprite-preview:hover {
    padding: 6px;
    transform: scale(400%);
    border: 1px solid #F17104;
    background: white;
  }

  .orange-border {
    padding: 1em;
    border: 2px solid #F17104;
  }

  .scene-editor {
    background: white;
    border: 1px solid #F17104;
  }

  .player-container {
    display: grid;
    grid-template-columns: 49.25% 49.25%;
    grid-gap: 1rem;
  }
</style>
