<template>
  <div>
    <form class="bg-white px-8 pt-6 pb-8 mb-4 shadow rounded">
      <div class="mb-6">
        <label for="name" class="label">Game Name</label><br>
        <input type="name" v-model="name" class="input border rounded p-2 w-full" id="name" ref="name" placeholder="Game Name" >
      </div>

      <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"  @click.prevent="createItem()">Create</button>
    </form>
  </div>
</template>

<script>
// import VueTrix from "vue-trix";

export default {
  name: 'GameTable',
  components: {
    // VueTrix
  },
  data () {
    return {
      gameTableId: null,
      name: "",
      gmUsers: [],
      userId: this.$store.getters.currentUserId,
      user: null
    }
  },
  created() {
    this.checkSignedIn();
  },
  methods: {
    signedIn () {
      return this.$store.state.signedIn
    },
    checkSignedIn() {
      if (!this.signedIn()){
        this.$router.replace('/');
      } else if (this.userId) {
        this.$http.plain.get(`/users/${this.userId}`)
          .then(response => {
            this.user = response.data
          })
          .catch(error => this.setError(error, 'Something went wrong'))
        } else {
          return false;
        }
      },
      async createItem () {
        this.gmUsers = this.gmUsers.push(this.userId)
        await this.$http.plain.post("/game_tables/", {
          name: this.name,
          user_id: this.userId
          // gm_users: this.gmUsers
        })
        .then((response) => {
          if (response.status === 201) {
            alert("success");
          } else {
            console.error("unable to save")
          }
        });
      }
    }
  }
</script>
