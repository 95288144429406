<template>
  <div>
    <div v-if="this.characterId" class="relative" id="GameTable">
      <p class="center absolute" style="padding-top: 80px; z-index:100; left:calc(50% -  50px); background-color:black;">
        {{gameName}}
      </p>
      <PlayerCharacter class="inline-block absolute" />
      <!-- Change this back map at somet point (??? Not sure what this note is supposed to mean) -->
      <Map
        class="absolute inline-block"
        :mapSettings="showMapControls"
        :gameSettings="showGameSettings"
        :currentTable="currentTable"
        :allPlayers="allPlayers"
        :currentPlayerId="currentPlayerId"
      />

      <div v-if="showOtherPlayers" class="fixed bg-sd-dark-blue rounded orange-border " style="bottom: 80px; left: 16px; z-index:200;">
        <div class="flex flex-col justify-between">
          <div v-for="(participant, index) in participants" :key="index">
            <!-- <label class="bg-sd-light-blue relative block center" style="font-size:0.6rem; bottom:-8px; text-align:center; max-width: 70px; left: 8px;">
              {{ participant.payload.name }}
            </label> -->
            <img
              :src="participant.image ? participant.image : '/img/sandragon_orange.8c62507b.png'"
              class="m-2 inline-block rounded relative md-box-shadow bg-sd-dark-blue "
              style="width:40px; height: 40px;"
            />
          </div>
        </div>
      </div>

      <div v-if="userIsGm" class="sd-font-dark-blue fixed" style=" color: blue !important; transform:scale(3); top:100px; left: 30px; z-index:300; opacity:0.75;">
        <a href="/game/edit">🅶</a>
      </div>
      <div
        id="game-bar"
        class="shadow-md fixed mx-auto rounded orange-border"
        style="background-color:#0E112F; width: 300px; height: 40px; z-index: 100; bottom: 20px; right:10px;"
      >
        <div class=" flex justify-around py-2" style="height:100%; flex-direction:row;" id="icon-bar">
          <div v-if="userIsGm" @click="toggleMapControls" class="sd-font-orange icon-bar-icons">
            <span class="absolute" style="font-size:0.5rem; color:white; transform:translate(2px, 4px);">Edit</span>
            <font-awesome-icon icon="fa-solid fa-map" />
          </div>
          <div class="sd-font-light-blue icon-bar-icons">
            <font-awesome-icon icon="fa-solid fa-id-badge" />
          </div>
          <div @click="toggleChat" class="sd-font-light-blue icon-bar-icons relative">
            <font-awesome-icon icon="fa-solid fa-message" />
            <span v-if="newMessageNotification" class="notification-dot">
              +
            </span>
          </div>
          <div class="sd-font-light-blue icon-bar-icons">
            <font-awesome-icon icon="fa-solid fa-scroll" />
          </div>
          <div @click="toggleFullscreen" class="sd-font-light-blue icon-bar-icons">
            <span v-if="isFull">
              <font-awesome-icon icon="fa-solid fa-minimize" />
            </span>
            <span v-else><font-awesome-icon icon="fa-solid fa-maximize" /></span>
          </div>
          <div @click="toggleGameSettings" class="sd-font-light-blue icon-bar-icons">
            <font-awesome-icon icon="fa-solid fa-gear" />
          </div>
          <div v-if="youtubeEmedUrl" @click="toggleMusic" class="sd-font-light-blue icon-bar-icons">
            <font-awesome-icon icon="fa-solid fa-music" />
          </div>
        </div>

         <div v-if="showGameSettings" class="bg-sd-light-blue character-icons game-controls">
          <!-- Turn this into a component -->
          <button
            class="bg-sd-dark-blue text-white py-1 px-2 control-button"
          >
            Character Modal
          </button>
          <button
            @click="toggleShowOtherPlayers"
            class="text-white py-1 px-2 control-button "
            :class="showOtherPlayers ? 'bg-sd-dark-blue' : 'bg-orange'"
          >
            <span v-if="showOtherPlayers">Hide Other Players</span>
            <span v-else>Show Other Players</span>
          </button>
          <br>
        </div>
        <div v-show="showMusic" v-if="notMuted" class="game-controls" style="height: max-content; width: 200px;">
          <iframe
            width="100%"
            height="200px"
            :src="youtubeEmedUrl"
            title="Hurdy-Gurdy Playlist"
            frameborder="0"
            allow="autoplay; clipboard-write; encrypted-media;"
            allowfullscreen>
          </iframe>
          <font-awesome-icon icon="fa-solid fa-volume-xmark" @click="stopMusic" v-if="showMusic" />
        </div>
        <div v-if="showChat" class="fixed bg-sd-light-blue p-1 rounded" style="width: 300px; height: 300px; bottom: 110px;">
          <div v-for="(message, index) in conversation" :key="index">
            <span>
             <b class="px-1" :class="message.sentBy === currentPlayerName ? 'msg-sent' : 'msg-received '">
              {{message.sentBy}}
              </b>
             <span class="font-light" style="font-size:.9rem;"> &nbsp; {{message.msg}}</span>
            </span><br>
          </div>
          <span class="fixed rounded" style="width: 300px; height: 30px; background-color: white; bottom:80px; right:10px;" >
            <input id="chat-input" class="border w-4/5 inline-block" v-model="message">
            <p @click="sendMessage" class="bg-orange px-2 inline-block" style="color:white; !important"> Send </p>
          </span>
        </div>
      </div>
    </div>

    <div v-else>
      Add a Character
      <br>
      <p
        v-for="character in userCharacters"
        :key="character.id"
        class="bg-purple-500 hover:bg-purple-700 text-white py-2 px-4 rounded w-max inline-block mr-2 my-2"
        @click="updateCharacterId(character.id)"
      >
        {{ parseName(character.payload) }}
      </p>
    </div>
  </div>
</template>

<script>
import PlayerCharacter from '../Characters/PlayerCharacter.vue'
import Map from '../Map.vue'

export default {
  name: 'Game',
  components: {
    PlayerCharacter,
    Map
  },
  data () {
    return {
      gameId: "",
      gameData: [],
      gameName: null,
      userId: this.$store.state.currentUser.id,
      player: null,
      editId: null,
      userCharacters: [],
      characterId: null,
      stateCharacterId: this.$store.state.characterId,
      user: null,
      isFull: false,
      userIsGm: false,
      mapImages: [],
      showMapControls: false,
      showGameSettings: false,
      defaultImage: {},
      participants: [],
      allPlayers: [],
      characterImages: [],
      currentTable: null,
      showOtherPlayers: true,
      message: "",
      received: "",
      showChat: false,
      messages: [],
      currentPlayer: null,
      currentPlayerName: "",
      fromPlayerName: "",
      newMessagesLength: 0,
      currentPlayerId: null,
      showMusic: false,
      notMuted: true,
      youtubeEmedUrl: null
    }
  },
  channels: {
    GameChatChannel: {
      connected() {
        console.log("Connected to the chat channel");
      },
      rejected() {},
      received(data) {
        if (data.gameId === this.gameId) {
          this.received = data
          this.messages.push({msg: this.received.content, sentBy: this.received.from} )
          if (this.showChat) {
            this.newMessagesLength = this.messages.length
          }
          this.fromPlayerName = this.received.from
        }
      },
      disconnected() {},
    },
  },
  created () {
    this.currentTable = this.$store.state.gameId;
    if(this.$store.state.gameId && this.signedIn()){
      this.gameId = this.$store.state.gameId
      this.fetchMapData()
      this.fetchPlayerFromGameTable();
    } else {
      window.location = "/"
    }
  },
  computed: {
    newMessageNotification() {
      return this.newMessagesLength !== this.messages.length
    },
    samePlayer() {
      return this.received.from === this.currentPlayerName
    },
    conversation(){
      return this.messages
    },
    newMessage() {
      return this.message
    }
  },
  methods: {
    sendMessage() {
      this.$cable.perform({
        channel: "GameChatChannel",
        action: "send_message",
        data: {
          content: this.newMessage,
          from: this.currentPlayerName,
          gameId: this.gameId,
        },
      });
      this.message = ""
    },
    stopMusic() {
      this.notMuted = !this.notMuted
      if(!this.notMuted){
        this.showMusic = false
      }
    },
    toggleMusic() {
      this.showMusic = !this.showMusic
      if(this.showMusic){
        this.notMuted = true
      }
    },
    toggleChat() {
      this.showChat = !this.showChat
      this.showGameSettings = false
      this.showMapControls = false
      if (this.showChat ) {
        this.newMessagesLength = this.messages.length
      }
    },
    toggleShowOtherPlayers () {
      this.showOtherPlayers = !this.showOtherPlayers
      this.showMapControls = false
      this.showGameSettings = false
      this.showChat = false
    },
    toggleGameSettings () {
      this.showGameSettings = !this.showGameSettings
      this.showMapControls = false
      this.showChat = false
    },
    toggleMapControls () {
      this.showMapControls = !this.showMapControls
      this.showGameSettings = false
      this.showChat = false
    },
    async fetchMapData () {
      await this.$http.plain.get(`/game_tables/${this.gameId}`)
      .then(response => {
        this.gameData = response.data
        this.mapImages = Array.from(this.gameData.images_urls)
        let payload = JSON.parse(this.gameData.payload)
        this.defaultImage = payload.default_image
        this.allPlayers = this.gameData.participant_characters
        this.participants = this.allPlayers.filter(e => e.user_id !== this.userId)
        this.currentPlayer = this.allPlayers.filter(e => e.user_id === this.userId)
        if (this.currentPlayer.length === 0) {
          this.currentPlayerName = "GM"
          this.currentPlayerId = -321 // this is a hack - fix this!
        }else {
          this.currentPlayerName = this.currentPlayer[0].payload.name
          this.currentPlayerId = this.currentPlayer[0].user_id
        }
      })
      .catch(error => this.setError(error, 'Something went wrong'))
      // this.youtubeEmedUrl = "https://www.youtube.com/embed/L8Nfpen6nK0"
      // connect to soundcloud instead
    },
    toggleFullscreen () {
      this.isFull = !this.isFull
      if (this.isFull === true) {
        this.fullscreen()
      } else {
        this.exitFullscreen()
      }
    },
    exitFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) { /* Safari */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { /* IE11 */
        document.msExitFullscreen();
      }
    },
    fullscreen () {
      const elem = document.getElementById("GameTable");
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) { /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) { /* IE11 */
        elem.msRequestFullscreen();
      }
    },
    signedIn () {
      return this.$store.state.signedIn
    },
    updateCharacterId (id) {
      this.$store.state.characterId = id
      this.$http.plain.patch(`/players/${this.player.id}`, {
        character_id: id,
      })
      //window.location.reload()
    },
    parseName(payload) {
      return JSON.parse(payload).name;
    },
    sortCharacters (data) {
      data.forEach(char => this.userCharacters.push(char));
    },
    addPlayerCharacterToGame () {
      this.getPlayerCharacters()
    },
    async getPlayerCharacters() {
      await this.$http.plain.get(`/users/${this.userId}/characters`)
      .then(response => {
        this.editId = response.data.id
        this.sortCharacters(response.data)
      })
      .catch(error => this.setError(error, 'Something went wrong'))
    },
    async fetchPlayerFromGameTable () {
      await this.$http.plain.get(`/game_tables/${this.gameId}/player`)
      .then(response => {this.player = response.data[0]})
      .catch(error => this.setError(error, 'Something went wrong'))

      if( this.player ) {
        this.characterId = this.player.character_id
        if(this.player.role === "gm") {
          this.userIsGm = true
        }
        if (!this.player.character_id || this.player.character_id === null ) {
          this.addPlayerCharacterToGame()
        } else if (this.player.character_id !== this.stateCharacterId) {
          this.$store.state.characterId = this.player.character_id
        }
      }
    }
  },
  mounted() {
    //need to add an id check for this
    this.$cable.subscribe({
      channel: "GameChatChannel"
    });
  }
}
</script>

<style scoped>
  .orange-border {
    border: 1px solid orange;
  }
  .icon-bar-icons {
    transform: scale(1.25);
  }
  .icon-bar-icons:hover {
    transform: scale(1.5);
  }
  .character-icons {
    opacity: 0.9;
    padding: 4px;
    position: fixed;
    width: 370px;
    color: white;
    right:10px;
    bottom: 100px;
    height: max-content;
  }

  .game-button {
    transform: scale(0.8);
  }

  .game-button:hover {
    transform: scale(0.85);
  }

  .msg-sent {
    background-color:#0E112F;
    color:#F17104;
  }

  .msg-received {
    background-color:#F17104;
    color: #0E112F;
  }
  .notification-dot {
    color: #F17104;
    font-size: .25rem;
    background: #F17104;
    height: 5px;
    padding: 1px;
    border-radius: 50%;
    position: absolute;
    left: 12px;
    top: 4px;
  }

  .game-controls {
    opacity: 0.9;
    padding: 4px;
    position: fixed;
    width: 260px;
    color: white;
    right: 10px;
    bottom: 100px;
    height: 400px;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
  }
  .control-button {
    transform: scale(0.8);
    width: 200px;
    text-align:center;
    margin: 0px auto;
  }
</style>

