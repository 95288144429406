<template>
  <div>
    <PlayerCharacter class="inline-block" />
    <Map class="inline-block w-full" style="height: calc(100% + 100px); overflow:scroll !important; position:fixed;" />
  </div>
</template>

<script>
import PlayerCharacter from './PlayerCharacter.vue'
import Map from '../Map.vue'

export default {
  name: 'Table',
  components: {
    PlayerCharacter,
    Map
  }
}
</script>

