<template>
  <div class="relative bg-sd-dark-blue relative" style="overflow:hidden;">
    <Stars style="z-index:1; opacity:0.6;" />
    <div class="mx-auto" style="height:100vh;">
      <div class="flex flex-col justify-center hero-nav-copy w-9/12 mx-auto" style="height:40%;">
        <div class="mx-auto h-full w-full flex flex-col justify-center">
          <p id="tile-nav-0-blurb" class="text-lg lg:text-xl animate-text sds-show" style="color: white;">
            <span>
              Welcome to Sandragon
            </span>
          </p>
          <p id="tile-nav-1-blurb" class="text-lg lg:text-xl animate-text sds-show inactive-blurb" style="color: white;">
            <span>
              From high fantasy to noir to beyond the stars, you can find your adventure on Sandragon.
            </span>
          </p>
          <p id="tile-nav-2-blurb" class="text-lg lg:text-xl animate-text content-show flex flex-col justify-center inactive-blurb" style="color: white;">
            <span>
              From earth rending spells, gadgets that would maked any dark knight envious,
              and modules that kick off unforgettable adventures, you'll find it here.
            </span>
          </p>
          <p id="tile-nav-3-blurb" class="text-lg lg:text-xl animate-text new-show inactive-blurb" style="color: white;">Sandragon System Rules and Tips</p>
          <p id="tile-nav-4-blurb" class="text-lg lg:text-xl animate-text atlantis-show flex flex-col justify-center inactive-blurb" style="color:white;" >
              <span class="text-center">Atalantis: Frost and Flood</span><span><br>{{ $store.state.postBlurb }}</span>
          </p>
          <p id="tile-nav-5-blurb" class="text-lg lg:text-xl animate-text tools-show flex flex-col justify-center inactive-blurb" style="color: white;">
            <span>All the means to keep the players and adventure organized and the GM happy.</span>
          </p>
        </div>
      </div>
      <div class="overflow-scroll p-4" style="width: 100%;">
        <div id="tile-desktop" class="mx-auto relative " style="width: max-content;"  @touchmove="getPosition()">
          <!-- <div class="inline-block tile" @mouseover="[showBlurb('tile-nav-1')]" @mouseleave="[hideBlurb('tile-nav-1')]" @click="routeTo('sandragon/system-overview', '')" :style="`margin-left:calc(${this.specialMargin}px - 16px);`">
            <div id="tile-nav-1" class="rounded tile-nav" style="background-size: cover; background-repeat: no-repeat; background-position: center; background-size: 130px;" :style="{ backgroundImage: 'url(' + require('@/assets/book.png') + ')' }">
            </div>
            <p class="sd-font-orange text-center tile-nav-label border-inherit">Sandragon</p>
          </div> -->
          <div class="inline-block tile"  @mouseover="[showBlurb('tile-nav-2')]" @mouseleave="[hideBlurb('tile-nav-2')]" @click="routeTo('content', '')">
            <div id="tile-nav-2" class="rounded tile-nav" style="background-size: cover; background-repeat: no-repeat; background-position: center; background-size: 130px;" :style="{ backgroundImage: 'url(' + require('@/assets/ritual.png') + ')' }">
            </div>
            <p class="sd-font-orange text-center tile-nav-label">Content</p>
          </div>
          <div class="inline-block tile"  @mouseover="[showBlurb('tile-nav-3')]" @mouseleave="[hideBlurb('tile-nav-3')]" @click="routeTo('sandragon/rules', '')">
            <div id="tile-nav-3" class="rounded tile-nav" style="background-image: url('/img/rotate.f79c232f.png');  background-size: contain; background-repeat: no-repeat; background-position: center;">
            </div>
            <p class="sd-font-orange text-center tile-nav-label">SDS</p>
          </div>
          <div class="inline-block tile"  @mouseover="[showBlurb('tile-nav-4')]" @mouseleave="[hideBlurb('tile-nav-4')]" @click="routeTo('atlantis-frost-and-flood', '')">
            <div id="tile-nav-4" class="rounded tile-nav" style="background-size: cover; background-repeat: no-repeat; background-position: center; background-size: 130px;" :style="{ backgroundImage: 'url(' + require('@/assets/waves.png') + ')' }">
            </div>
            <p class="sd-font-orange text-center tile-nav-label">Atlantis</p>
          </div>
          <!-- <div class="inline-block tile"  @mouseover="[showBlurb('tile-nav-5')]" @mouseleave="[hideBlurb('tile-nav-5')]" @click="routeTo('map', '')"  :style="`margin-right:calc(${this.specialMargin}px - 16px);`">
            <div id="tile-nav-5" class="rounded tile-nav" style="background-size: cover; background-repeat: no-repeat; background-position: center; background-size: 130px;" :style="{ backgroundImage: 'url(' + require('@/assets/hand.png') + ')' }">
            </div>
            <p class="sd-font-orange text-center tile-nav-label">Tools</p>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Stars from './UI/Stars'

export default {
  name: 'homepage',
  components: {
    Stars
  },
  data () {
    return {
      post: {},
      error: '',
      activeContentSlug: '',
      activeContentImage: '',
      activePostSlug: '',
      activePostImage: '',
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      show5: false,
      showDefault: true,
      active: false,
      winWidth: 0,
      specialMargin: 0,
      mobileActive: false,
    }
  },
  created () {
    const blrbZero = document.querySelector("#tile-nav-0-blurb")
    this.getActive('materials')
    this.getActive('posts')
    this.getViewWidth()
    if (blrbZero) {
      blrbZero.classList.add("active-blurb");
    }
  },
  methods: {
    getPosition () {
      let leftWidth = (this.winWidth / 2) - 182;
      let rightWidth = (this.winWidth / 2) + 182;
      const childTiles = ["tile-nav-1","tile-nav-2","tile-nav-3","tile-nav-4","tile-nav-5"]
      for (const tile of childTiles) {
        const elmnt = document.querySelector("#"+tile)
        const rect = elmnt.getBoundingClientRect();
        if(rect.left >= leftWidth && rect.right <= rightWidth) {
          elmnt.classList.add("active-border");
          const showBlrb = tile
          this.showBlurb(showBlrb);
        }else {
          elmnt.classList.remove("active-border");
          const hideBlrb = tile
          this.hideBlurb(hideBlrb);
        }
      }
    },
    showBlurb(elx){
      const blrbZero = document.querySelector("#tile-nav-0-blurb")
      blrbZero.classList.remove("active-blurb");
      blrbZero.classList.add("inactive-blurb");
      const blrb = document.querySelector("#"+elx+"-blurb")
      blrb.classList.remove("inactive-blurb");
      blrb.classList.add("active-blurb");
    },
    hideBlurb(ely){
      const hblrb = document.querySelector("#"+ely+"-blurb")
      hblrb.classList.remove("active-blurb");
      hblrb.classList.add("inactive-blurb");
    },
    getViewWidth() {
      this.winWidth = window.innerWidth
      if( this.winWidth < 860){
        this.specialMargin = (this.winWidth / 2) - 75;
      }else {
        this.specialMargin = 0;
      }
    },
    setError (error, text) {
      this.error = (error.response && error.response.data && error.response.data.error) || text
    },
    getPostImage () {
      return this.post.image_url;
    },
    routeTo(where,slug) {
       window.location = "/"+ where + "/" + slug
    },
    async getActive(items){
      let data = []
      await this.$http.secured.get('/'+items)
        .then(response => { data = response.data})
        .catch(error => this.setError(error, 'Something went wrong'))
      for (const item of data) {
        if (item.active === true ){
          if (items === "materials"){
            this.$store.commit('setActiveContentSlug', item.slug )
            this.activeContentSlug = item.slug
            this.activeContentImage = item.image_url
          }else if (items === "posts"){
            this.$store.commit('setActivePostSlug', item.slug )
            this.activePostSlug = item.slug
            this.activePostImage = item.image_url
          }
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .inactive-blurb {
    display: none;
  }
  .active-blurb {
    display: unset
  }
  .inactive-border {
     border: 2px solid #ed722600;
  }

  .active-border {
    border: 2px solid #ED7126;
  }
  .tile-nav-label {
    margin-top: 1rem;
  }
  .tile-nav:hover {
    border: 2px solid #ED7126;

    .inactive-border {
      border: 0px solid #ed722600;
    }
  }

  .tile-nav {
    width: 150px;
    height: 150px;
    cursor: pointer;
    margin: 0 16px;
  }

  .animate-text {
    margin: 0 auto;
    max-width: 700px;
    text-align: center;
  }

  .grid-nav:hover {
    .animate-text {
      display:block;
    }
  }

  // .dim-background {
  //    background-color:rgba(0,0,0,0.5) !important;
  // }

  // .opaque-dark-blue {
  //   background-color:rgba(015,016,047,0.7) !important;
  // }

  .star-gradient {
    background: rgb(15,16,47);
    background: linear-gradient(180deg, rgba(15,16,47,1) 0%, rgba(15,16,47,0.8674063375350141) 35%, rgba(255,177,0,0.640515581232493) 83%);
  }

  @media (max-width: 640px) {

  }
</style>
