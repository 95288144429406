// https://github.com/hanhdt/vue-trix
<template>
  <div class="items-center relative">
    <div
      :class="{ dimbackground: showModal }"
      class="bg-purple-100 py-20 relative"
    >
      <p
        v-if="article.id"
        class="mx-auto py-2 text-green-500 mb-6"
        style="font-size:2rem;"
      >
       Article Editor
      </p>
      <button
        class="bg-green-500 text-white font-bold py-2 px-4 ml-2 rounded mx-2 absolute"
        style="top: 20px;"
        @click="routeTo('wiki')"
      >
        Back to Wiki
      </button>
      <button
        v-if="articleId"
        class="bg-red-500 text-white font-bold py-2 px-4 rounded mx-2 absolute"
        style="left: 90%; top: 20px;"
        @click="deleteItemCheck"
      >
        Delete
      </button>
      <div class="p-4">
        <form class="bg-white px-8 pt-6 pb-8 mb-4 shadow rounded">
          <p
            v-if="article.id"
            class="py-2 mb-12 text-center"
            style="font-size:2rem;"
          >
            {{ article.label }}
            <span class="block text-xs">
              Last updated: {{ updatedAt }}
            </span>
          </p>

          <p
            v-else
            class="bg-blue-200 rounded w-2/5 mx-auto py-2 text-blue-500 mb-12 text-center"
            style="font-size:2rem;"
          >
            New Wiki Article
          </p>
          <div v-if="isArticleBeingSaved" >
            <Loading />
          </div>
          <div class="mb-6">
            <label for="label" class="label">Label</label><br>
            <input type="label" v-model="label" class="input border rounded p-2  w-2/5" id="label" ref="label" >
          </div>
          <div class="mb-6">
            <label for="body" class="label">Body</label><br>
            <VueTrix id="body" v-model="body" :value="body" class="border rounded p-2 w-full" />
          </div>
          <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" v-if="(articleId === null)"  @click.prevent="createItem()">Create</button>
          <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" v-if="articleId" @click.prevent="createItem()">Update</button>
        </form>
      </div>
    </div>
    <div v-if="showModal" class="bg-white rounded w-2/5 mx-auto shadow p-10 absolute" style="left:30%; top:calc(0px + 20vh);">
      <p class="my-10">Are you sure you want to delete this?</p>
      <div class="flex flex-row justify-between">
        <button
          class="bg-blue-500 text-white font-bold py-2 px-4 rounded inline-block"
          @click="toggleDelete()"
        >
          Nope!
        </button>
        <button
          class="bg-red-500 text-white font-bold py-2 px-4 rounded inline-block"
          @click="deleteItem()"
        >
          Yep!
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import VueTrix from "vue-trix";
import Loading from '../UI/Loading.vue'

export default {
  name: 'Editor',

  components: {
    VueTrix,
    Loading
  },
  data () {
    return {
      articleId: null,
      label: "",
      body: "",
      article: [],
      showModal: false,
      tags: [],
      category: "",
      savingArticle: false,
      updatedAt: null
    }
  },
  computed: {
    isArticleBeingSaved () {
      return this.savingArticle
    }
  },
  created () {
    if (!this.$store.state.signedIn && this.$store.state.currentUser.role !== "admin") {
      this.$router.replace('/');
    }else if(this.$store.state.articleId){
      this.articleId = this.$store.state.articleId
      this.fetchArticleData();
    }else {
      return
    }
  },
  methods: {
    routeTo(location) {
       window.location = "/" + location
    },
    async fetchArticleData () {
      await this.$http.plain.get(`/articles/${this.articleId}`)
        .then(response => { this.article = response.data })
        .catch(error => this.setError(error, 'Something went wrong'))
      this.label = this.article.label
      this.body = this.article.body
      this.updatedAt = this.article.updated_at
      this.updatedAt = this.updatedAt.toLocaleDateString();
    },
    deleteItemCheck () {
      this.toggleDelete();
    },
    deleteItem () {
      this.$http.plain.delete(`/articles/${this.articleId}`)
      this.$store.state.articleId = null
      window.location = "/wiki";
    },
    toggleDelete () {
      this.showModal = !this.showModal;
    },
    async createItem () {
      if (this.articleId) {
        this.savingArticle = true;
        await this.$http.plain.patch(`/articles/${this.articleId}`, {
          label: this.label,
          body: this.body,
          author: this.$store.state.currentUser.username,
          tags: this.tags
        })
        .then((response) => {
          if (response.staus === "200") {
            this.savingArticle = false
          } else {
            this.savingArticle = false
            console.error("unable to save")
          }
        });
      } else {
        await this.$http.plain.post("/articles/", {
          label: this.label,
          body: this.body,
          author: this.$store.state.currentUser.username,
          tags: this.tags,
          category: this.category
        })
        .then((response) => {
          if (response.status === 201) {
            this.routeTo('wiki');
          } else {
            console.error("unable to save")
          }
        });

      }
    }
  }
}
</script>
