<template>
  <div ref="mountPoint" style="width:100px; height: 100px;"></div>
</template>

<script>
import * as THREE from 'three';
// import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
// import oc from 'three-orbit-controls'
// const OrbitControls = oc(THREE)
// import * as dat from 'lil-gui'
// const gui = new dat.GUI()

export default {
  props: {
    modelUrl: {
      type: String,
      required: true,
      default: "http://localhost:3000/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcFlCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--915711b07c5c313c98ea280cd9e09a9889d47475/SpruceTree3.gltf" // change this back to false
    }
  },
  data() {
    return {
      scene: null,
      camera: null,
      renderer: null,
      animationFrameId: null,
      light: null,
      GLTFLoader: null,
      modelGroup: null,
      cube: null,
      orbitControl: null
    };
  },
  mounted() {
    this.initThree();
  },
  methods: {
    load3d() {
      this.gltfLoader = new GLTFLoader()
      this.modelGroup = new THREE.Group();
      this.gltfLoader.load(this.modelUrl,
      (gltf) =>{
        let model = gltf.scene
        // model.rotation.order = 'YXZ';
        // model.rotation.x = Math.PI / 2;
        model.position.z = 2
        model.position.y = 1
        model.position.x = 1
        model.scale.x = 1
        model.scale.y = 1
        model.scale.z = 1
        this.modelGroup.add(model)
        this.scene.add(this.modelGroup)

        // gui.add(this.modelGroup.rotation, 'x').min(0.001).max(1000).step(.01)
        // gui.add(this.modelGroup.rotation, 'y').min(0.001).max(1000).step(.01)
        // gui.add(this.modelGroup.rotation, 'z').min(0.001).max(1000).step(.01)
      });
    },
    initThree() {
      this.scene = new THREE.Scene();
      const size = 10;
      const divisions = 10;
      const gridHelper = new THREE.GridHelper( size, divisions );
      this.scene.add( gridHelper );
      this.camera = new THREE.PerspectiveCamera(75, 100 / 100,0.1,1000);
      this.camera.position.z = 4.5;
      this.camera.position.x = 1
      this.camera.position.y = 2
      this.scene.add(this.camera)
      this.light = new THREE.AmbientLight( 0xffffff ); // soft white light
      this.scene.add( this.light );
      this.renderer = new THREE.WebGLRenderer({ antialias: false });
      this.renderer.setSize(100, 100);
      this.renderer.setPixelRatio(Math.min(window.devicePixelRatio * 2.0))

      this.$refs.mountPoint.appendChild(this.renderer.domElement);
      this.scene.background = new THREE.Color(0xffffff)
      this.load3d()
      this.animate();
    },
    animate() {
      this.renderer.render(this.scene, this.camera)
      requestAnimationFrame( this.animate )
    },
  },
};
</script>