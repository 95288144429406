<template>
  <div
      class="py-3 absolute text-center m-auto w-full"
      style="top:50%;"
    >
    <p class="text-4xl w-1/3 m-auto loading relative" style="z-index:2; opacity: 0.70;">summoning</p>
    <img src="../../assets/sandragon_blue.png" class="m-auto relative" style="width: 50px; top:-50px; z-index:1; opacity: 0.55;">
    <Fire style="top:-40px; height: auto; opacity: 0.55;" />
  </div>
</template>

<script>
import Fire from '../Flare/Fire'

export default ({
  name: 'loading',
  components: {
    Fire
  }
})
</script>


<style scoped>
  .loading {
    /* background-color: #0F102F;
    color: #ED7126; */
    color: #0F102F
  }
</style>