<template>
  <div class="px-10 pt-40">
    <!-- This is shows for player_users. Need to update to show if GM user -->
    <button
      class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
      @click=createGame()
    >
      Create new game
    </button>
    <br><br>
    <div v-if="gamesList.length > 0">
      <p>Available Games</p>
      <p
        v-for="game in gamesList"
        :key="game.id"
        @click="routeTo(game.id)"
        class="bg-purple-500 hover:bg-purple-700 text-white py-2 px-4 rounded w-max inline-block mr-2 my-2"
      >
        {{ game.name }}
      </p>
    </div>
    <p v-else >You are not associated with any games.</p>
  </div>
</template>

<script>

export default {
  name: 'Games',
  components: {
  },
  data () {
    return {
      gameTableId: null,
      userId: this.$store.getters.currentUserId,
      userEmail: this.$store.getters.currentUserEmail,
      user: null,
      gamesList: []
    }
  },
  created () {
    this.checkSignedIn();
    this.fetchAvailableGames();
  },
  mounted () {

  },
  methods: {
    createGame() {
      this.$store.commit('setGameId', null)
      window.location = "/game/edit"
    },
    // turn routeTo into a component
    routeTo(id) {
      this.$store.commit('setGameId', id)
      localStorage.setItem("gametable", id);
      window.location = "/game"
    },
    signedIn () {
      return this.$store.state.signedIn
    },
    async checkSignedIn() {
      if (!this.signedIn()){
        this.$router.replace('/');
      } else if (this.userId) {
        try {
          const response = await this.$http.plain.get(`/users/${this.userId}`)
          this.user = response.data
        } catch (error) {
          console.error(error);
          console.error('Something went wrong');
        }
      } else {
        return false;
      }
    },
    async fetchAvailableGames() {
      try {
        const response = await this.$http.plain.get(`/users/${this.userId}/game_tables/`);
        if (response.data.length > 0 ){
          this.sortGameList(response.data)
        }
      } catch (error) {
        console.error(error);
        console.error('Something went wrong');
      }
    },
    sortGameList (data) {
      this.gamesList = data.filter(n => n);
    },
  }
  }
</script>
