<template>
  <div class="relative pt-10 w-full mx-auto bg-sd-dark-blue" style="overflow:hidden;">
    <Stars style="opacity:0.6; z-index: 1;"/>

    <h1 class="mx-auto w-full" style="font-size:2em; text-align: center; position:fixed; height: 40px; color: white;"> Character Edit Page </h1>
    <PlayerCharacter style="width:100vw; max-width: 800px; height:fit-content; z-index:10; position:relative;" />
  </div>
</template>

<script>
import PlayerCharacter from './PlayerCharacter.vue'
import Stars from '../UI/Stars.vue'

export default {
  name: 'CharEdit',
  components: {
    PlayerCharacter,
    Stars
  }
}
</script>

