//Add stats map to player - ie a chart that color codes a players stats
// If player is GM - have the character icon open the GM tools rather than character
// move dice rolls to backend

<template>
  <div class="characters mx-auto relative;" style="overflow: scroll;">
    <div
      v-if="userCharacter || characterId === 0"
      :class="{ dimbackground: showModal }"
    >
      <form class="form-app form-edit" @submit.prevent="updateCharacter">
        <div class="form-group m-4 pt-8">

          <!-- Character Column -->
          <div v-if="!onEditPage" class="rounded"  style="border: 1px solid orange; background-color:#0F102F; position:absolute; left:16px; bottom: 18px; cursor:pointer; position: fixed; z-index: 10;">
            <img
              :src="charImageUrl"
              class="inline-block transparent-bg w-full rounded md-box-shadow relative"
              style="width: 40px; height: 40px; margin: 8px; "
              :style="(shiftCharacterBar ? 'display: inline-block;' : 'display:none;')"
              @click="toggleBar()"
            />
          </div>
          <div
            class="h-full p-1 bg-sd-light-blue rounded"
            :class="(onEditPage ? 'w-full full-width-sheet relative': 'w-3/5 fixed')"
            style="top:80px; left:-2px; z-index:21; height:100vh; min-width: 380px; overflow:scroll !important; "
            :style="(shiftCharacterBar ? 'left:-100%;' : ''  )"
          >
            <!-- Image and Competence -->
            <div class="block relative" >
              <div @click="addCharImage()" class="inline-block cursor-pointer character-image w-1/5" :style="(newCharacter() ? 'pointer-events:none;' : '' )">
                <img :src="charImageUrl" class="inline-block transparent-bg w-full" style="background-color: orange;" />
                <p v-if=onEditPage class="hidden-text">Change Image</p>
                <label for="image" style="display:none;" />
                <input type="file" style="display:none; pointer-events: none;" name="image" @change="uploadImage()" ref="inputPic" id="characterImage" />
              </div>
              <div class="inline-block w-2/3 p-2" style="font-size: 1em;">
                <p class="inline-block" id="spentPoints" :class="(hasOverSpent ? 'green-font':'red-font')" ref="spentPoints"> {{spentPoints}} / </p>
                <input type="title" v-model="charPoints" class="bg-transparent inline-block input" style="cursor: pointer;" id="points" ref="points" :placeholder="charPoints" v-debounce:3s="updateCharacter">
                <p class="block" id="spentPoints" style="font-size:12px;" ref="spentPoints">Points left: {{charPoints - spentPoints}}</p>
              </div>

              <p v-if="!onEditPage" class="absolute rounded" style="left: calc(100% - 14px); top: -4px; cursor:pointer; background-color: orange; padding: .01em .25em;"  @click="toggleBar()">X</p>
            </div>

            <!-- Name and Nav icons -->
            <div class="block w-full p-1">
              <label class="font-bold" style="display:none;" />
              <input
                v-debounce:3s="updateCharacter"
                type="title"
                id="name"
                v-model="charName"
                class="name-box font-bold input  w-full my-2"
                style="font-size:1.1rem; color: black; background: rgba(0,0,0,0.5);"
                ref="title"
                placeholder="Character Name"
              >
            </div>
            <div class="block py-1 w-full" @click="hideEdit">
              <img src="@/assets/id-card.png" class="inline-block mx-3 icon-hover" style="width:20px; height: 20px;" @click="toggleShow('description')"/>
              <img src="@/assets/backpack.png" class="inline-block mx-3 icon-hover" style="width:20px; height: 20px;" @click="toggleShow('gear')"/>
              <img src="@/assets/rubik.png" class="inline-block mx-3 icon-hover" style="width:20px; height: 20px;" @click="toggleShow('play')"/>
              <img src="@/assets/notebook.png" class="inline-block mx-3 icon-hover" style="width:20px; height: 20px;" @click="toggleShow('abilities')"/>
            </div>

            <div class="block w-full mx-auto relative light-bg-back ">
              <!--  Show Char Info -->
              <div v-if="activeShow === 'description'" class="small-font character-bar py-2" id="char-info" v-debounce:3s="updateCharacter">
                <div style="font-size:12px;">
                  <p class="block px-2 font-bold">Description:</p>
                  <textarea type="textarea" v-model="charDescription" class="textarea75 w-full px-2"  id="description" ref="description" :placeholder="charDescription" />
                </div>
                <div style="font-size:12px;">
                  <p class="block px-2 font-bold">Background:</p>
                  <textarea type="textarea" v-model="charBackground" class="textarea75 border px-2 w-full" id="background" ref="background" :placeholder="charBackground" />
                </div>
                <div style="font-size:12px;">
                  <p class="block px-2 font-bold">Profession:</p>
                  <textarea type="textarea" v-model="charProfessions" class="border px-2 w-full" id="background" ref="background" :placeholder="charProfessions" />
                </div>
                <div style="font-size:12px;">
                  <p class="block px-2 font-bold">Recreations:</p>
                  <textarea type="textarea" v-model="charRecreations" class="border px-2 w-full" id="background" ref="background" :placeholder="charRecreations" />
                </div>
              </div>

              <!-- Show Notes and Item -->
              <div  v-if="activeShow === 'gear'" class="small-font character-bar py-2" id="notes" v-debounce:3s="updateCharacter">
                <div style="font-size:12px;">
                  <p class="block px-2 font-bold">items:</p>
                  <textarea type="textarea" v-model="charItems" class="textarea75 w-full px-2"  id="items" :placeholder="charItems" />
                </div>
                <!-- Add ability to for user to add their own categories -->
                <div style="font-size:12px; pointer-events:none;">
                  <p class="block px-2 font-bold">Magic Items:</p>
                  <textarea type="textarea" class="textarea75 border px-2 w-full" placeholder="Coming Soon" />
                </div>
                <div style="font-size:12px; pointer-events:none;">
                  <p class="block px-2 font-bold">Weapons and Armor:</p>
                  <textarea type="textarea" class="border px-2 w-full" placeholder="Coming Soon" />
                </div>
                <div style="font-size:12px; pointer-events:none;">
                  <p class="block px-2 font-bold">Other:</p>
                  <textarea type="textarea" class="border px-2 w-full" placeholder="Coming Soon" />
                </div>
                <div style="font-size:12px;">
                  <p class="block px-2 font-bold">Notes:</p>
                  <textarea type="textarea" v-model="charNotes" class="textarea75 w-full px-2" id="notes" ref="backnotesground" :placeholder="charNotes" />
                </div>
              </div>

              <!-- Show Dice -->
              <div v-if="activeShow === 'play'" class="small-font character-bar" id="play" v-debounce:3s="updateCharacter">
                <p class="block px-1 font-bold right" style="cursor:pointer;" @click="toggleEditCompetence()">
                  <button class="bg-orange rounded p-1 py-0 text-center small-font" style="color: white; font-size: .75rem;">Edit</button>
                </p>
                <div class="block">
                  <div class="block comp-grid" :style="rollable ? '':'pointer-events:none;'">
                    <p class="font-bold rounded p-2 mb-1 inline-block mx-1 bg-hover" style="font-size:0.8em;" @click="roll(modifiedPer ,'perception')">
                      <span class="stat-label hover:text-red-500">Per </span>
                      <br>
                      <span class="stat">{{Perception}}</span>
                    </p>
                    <p class="font-bold rounded p-2 mb-1 inline-block mx-1 bg-hover" style="font-size:0.8em;" @click="roll(modifiedCor ,'coordination')"><span class="stat-label">Coo</span><br><span class="stat">{{Coordination}}</span></p>
                    <p class="font-bold rounded p-2 mb-1 inline-block mx-1 bg-hover" style="font-size:0.8em;" @click="roll(modifiedInt ,'intuition')"><span class="stat-label">Int</span><br><span class="stat">{{Intuition}}</span></p>
                    <p class="font-bold rounded p-2 mb-1 inline-block mx-1 bg-hover" style="font-size:0.8em;" @click="roll(modifiedWit ,'wit')"><span class="stat-label">Wit</span><br><span class="stat">{{Wit}}</span></p>
                    <p class="font-bold rounded p-2 mb-1 inline-block mx-1 bg-hover" style="font-size:0.8em;" @click="roll(modifiedCul ,'culture')"><span class="stat-label">Cul</span><br><span class="stat">{{Culture}}</span></p>
                  </div>
                  <div class="block comp-grid" :style="rollable ? '':'pointer-events:none;'">
                    <p class="font-bold rounded p-2 inline-block mx-1 bg-hover" style="font-size:0.8em;" @click="roll(modifiedVir ,'Virtue & Vice')"><span class="stat-label">Virt</span> <br><span class="stat">{{VirtueVice}}</span></p>
                    <p class="font-bold rounded p-2 inline-block mx-1 bg-hover" style="font-size:0.8em;" @click="roll(modifiedEmp ,'empathy')"><span class="stat-label">Emp</span>  <br><span class="stat">{{Empathy}}</span></p>
                    <p class="font-bold rounded p-2 inline-block mx-1 bg-hover" style="font-size:0.8em;" @click="roll(modifiedMob ,'mobility')"><span class="stat-label">Mob</span>  <br><span class="stat">{{Mobility}}</span></p>
                    <p class="font-bold rounded p-2 inline-block mx-1 bg-hover" style="font-size:0.8em;" @click="roll(modifiedBra ,'brawn')"><span class="stat-label">Bra</span><br><span class="stat">{{Brawn}}</span></p>
                    <p class="font-bold rounded p-2 inline-block mx-1 bg-hover" style="font-size:0.8em;" @click="roll(modifiedVig ,'vigor')"><span class="stat-label">Vig</span> <br><span class="stat">{{Vigor}}</span></p>
                  </div>
                  <div class="block pt-2">
                    <input class="input rounded p-2 center inline-block border shadow w-10/12" style="height:38px; transform:translate(1px,1px);" id="customDie" placeholder="Custom Die Roller" @keyup="dieValue" >
                    <button class="bg-blue-500 font-bold bg-hover inline-block p-2 white-font shadow" style="transform: translate(-4px, 0px); font-size:.9em; border-radius: 0 8px 8px 0;" @click="roll(dieValue() ,'custom')">Roll</button>
                  </div>
                  <div class="inline-block light-bg-back w-full " style="postiion: relative; height: 82px;">
                    <div class="inline-block w-7/12" style="position: absolute; height:70px;transform: translate(-3px, 5px); padding-top: 0px;" :style="rollable ? 'visibility:hidden;':''">
                      <div class="lds-circle"><div>
                      <img src="/img/sandragon_orange.8c62507b.png" width="30px;" style="transform: translate(9px, 2px)"/>
                      </div></div>
                    </div>
                    <div class="rounded p-4 w-full" id="history2" ref="history" :style="rollable ? '':'visibility:hidden;'"/>
                  </div>
                </div>
                <div style="overflow:scroll !important; max-height: 200px;">
                  <div class="w-full mx-1 block relative" style="z-index:1; " >
                    <div class="rounded p-2 w-full bg-sd-dark-blue white-font textarea150" style="pointer-events:none; overflow-y:scroll !important;" id="history" ref="history">
                      {{ charHistory }}
                    </div>
                  </div>
                </div>
              </div>
                <!-- Competence save for edit -->
              <div class="edit-competence rounded p-3" :class="(editCompetence? 'show-edit-competence': '')" style="border: 2px solid orange;">
                <div class="w-full mx-1 inline-block" :style="rollable ? '':'pointer-events:none;'">
                  <div class="inline-block w-full px-10 " v-debounce:2s="updateComps">
                    <div class="w-3/6 inline-block">
                      <label class="font-bold bg-hover block" style="font-size:0.9em;" @click="roll((modifiedPer) ,'perception')">Perception</label>
                      <input type="number" v-model="Perception" class="inline-block input rounded p-2 center" id="Perception" style="width:60px;" value="1" ref="Perception" :placeholder="Perception">
                      <input type="number" v-model="tempPer" class="inline-block input rounded p-2 center white-font bg-sd-dark-blue" id="tempPer" style="width:60px;" value=0 ref="tempPer" placeholder=0 required @keyup="(updateStat())">
                    </div>
                    <div class="w-3/6 inline-block">
                      <label class="font-bold bg-hover block" style="font-size:0.9em;" @click="roll(modifiedCor ,'coordination')">Coordination</label>
                      <input type="number" v-model="Coordination" class="input inline-block rounded p-2 center" id="Coordination" style="width:60px;" ref="Coordination" :placeholder="Coordination">
                      <input type="number" v-model="tempCor" class="input inline-block rounded p-2 center white-font bg-sd-dark-blue" id="tempCor" style="width:60px;" value=0 ref="tempCor" @keyup="(updateStat())" >
                    </div>
                    <div class="w-3/6 inline-block">
                      <label class="font-bold bg-hover block" style="font-size:0.9em;" @click="roll(modifiedInt ,'intuition')">Intuition</label>
                      <input type="number" v-model="Intuition" class="input inline-block rounded p-2 center" id="Intuition" style="width:60px;" ref="Intuition" :placeholder="Intuition" >
                      <input type="number" v-model="tempInt" class="input inline-block rounded p-2 center white-font bg-sd-dark-blue" id="tempInt" style="width:60px;" value=0 ref="tempInt" @keyup="(updateStat())" >
                    </div>
                    <div class="w-3/6 inline-block">
                      <label class="font-bold bg-hover block" style="font-size:0.9em;" @click="roll(modifiedWit ,'wit')">Wit</label>
                      <input type="number" v-model="Wit" class="input inline-block rounded p-2 center" id="Wit" style="width:60px;" ref="Wit" :placeholder="Wit">
                      <input type="number" v-model="tempWit" class="input inline-block rounded p-2 center white-font bg-sd-dark-blue" id="tempWit" style="width:60px;" value=0 ref="tempWit" :placeholder="tempWit" @keyup="(updateStat())">
                    </div>
                    <div class="w-3/6 inline-block">
                      <label class="font-bold bg-hover block" style="font-size:0.9em;" @click="roll(modifiedCul,'culture')">Culture</label>
                      <input type="number" v-model="Culture" class="input inline-block rounded p-2 center" id="Culture" style="width:60px;" ref="Culture" :placeholder="Culture">
                      <input type="number" v-model="tempCul" class="input inline-block rounded p-2 center white-font bg-sd-dark-blue" id="tempCul" style="width:60px;" value=0 ref="tempCul" :placeholder="tempCul" @keyup="(updateStat())">
                    </div>
                    <div class="w-3/6 inline-block">
                      <label class="font-bold bg-hover block" style="font-size:0.9em;" @click="roll(modifiedVir ,'virtue')">Virtue & Vice</label>
                      <input type="number" v-model="VirtueVice" class="input inline-block rounded p-2 center" id="VirtueVice" style="width:60px;" ref="VirtueVice" :placeholder="VirtueVice">
                      <input type="number" v-model="tempVir" class="input inline-block rounded p-2 center white-font bg-sd-dark-blue" id="tempVir" style="width:60px;" value=0 ref="tempVir" :placeholder="tempVir" @keyup="(updateStat())">
                    </div>
                    <div class="w-3/6 inline-block">
                      <label class="font-bold bg-hover block" style="font-size:0.9em;" @click="roll(modifiedEmp ,'empathy')">Empathy</label>
                      <input type="number" v-model="Empathy" class="input inline-block rounded p-2 center" id="Empathy" style="width:60px;" ref="Empathy" :placeholder="Empathy">
                      <input type="number" v-model="tempEmp" class="input inline-block rounded p-2 center white-font bg-sd-dark-blue" id="tempEmp" style="width:60px;" value=0 ref="tempEmp" :placeholder="tempEmp" @keyup="(updateStat())">
                    </div>
                    <div class="w-3/6 inline-block">
                      <label class="font-bold bg-hover block" style="font-size:0.9em;" @click="roll(modifiedMob ,'mobility')">Mobility</label>
                      <input type="number" v-model="Mobility" class="input inline-block rounded p-2 center" id="Mobility" style="width:60px;" ref="Mobility" :placeholder="Mobility">
                      <input type="number" v-model="tempMob" class="input inline-block rounded p-2 center white-font bg-sd-dark-blue" id="tempMob" style="width:60px;" value=0 ref="tempMob" :placeholder="tempMob" @keyup="(updateStat())">
                    </div>
                    <div class="w-3/6 inline-block">
                      <label class="font-bold bg-hover block" style="font-size:0.9em;" @click="roll(modifiedBra ,'brawn')">Brawn</label>
                      <input type="number" v-model="Brawn" class="input inline-block rounded p-2 center" id="Brawn" style="width:60px;" ref="Brawn" :placeholder="Brawn">
                      <input type="number" v-model="tempBra" class="input inline-block rounded p-2 center white-font bg-sd-dark-blue" id="tempBra" style="width:60px;" value=0 ref="tempBra" :placeholder="tempBra" @keyup="(updateStat())">
                    </div>
                    <div class="w-3/6 inline-block">
                      <label class="font-bold bg-hover block" style="font-size:0.9em;" @click="roll(modifiedVig ,'vigor')">Vigor</label>
                      <input type="number" v-model="Vigor" class="input inline-block rounded p-2 center" id="Vigor" style="width:60px;" ref="Vigor" :placeholder="Vigor">
                      <input type="number" v-model="tempVig" class="input inline-block rounded p-2 center white-font bg-sd-dark-blue" id="tempVig" style="width:60px;" value=0 ref="tempVig" :placeholder="tempVig" @keyup="(updateStat())">
                    </div>
                  </div>
                </div>
              </div>

               <!--  Show Abilities -->
              <div
                v-if="activeShow === 'abilities'"
                class="small-font character-bar py-2"
                id="char-info"
                v-debounce:3s="updateCharacter"
                style="height: 100%; overflow:scroll !important;"
              >
                <div style="font-size:12px;">
                  <p class="block px-1 font-bold right" style="cursor:pointer;" @click="toggleEditAbilities()">
                    <button class="bg-orange rounded p-1 py-0 text-center" style="color: white;">Edit</button>
                  </p>
                  <p class="block px-1 font-bold">Powers: {{charPowers.length || 0 }}</p>
                  <div
                    class="block p-1"
                    v-for="(power, index) in charPowers" :key="index"
                  >
                    <p class="power inline-block w-full" style="border-bottom:1px solid rgba(128, 128, 128, 0.5);" > {{charPowers[index]}}</p>
                  </div>

                  <p class="block px-1 font-bold">Maneuvers: {{charManeuvers.length || 0 }}</p>
                  <div
                    class="block p-1"
                    v-for="(maneuver, index) in charManeuvers" :key="index"
                  >
                    <p class="maneuvers inline-block w-full " style="border-bottom:1px solid rgba(128, 128, 128, 0.5);" > {{charManeuvers[index]}}</p>
                  </div>
                </div>
              </div>
              <!-- Powers and Maneuvers EDIT -->
                <div class="edit-abilities p-2 rounded absolute" style="border: 2px solid orange; z-index:11;" :class="(editAbilites ? 'show-edit-abilities': '')">
                  <button
                      class="bg-red-400 text-white py-0 px-1 rounded"
                      style="float:right; cursor:pointer;"
                      @click="toggleEditAbilities()"
                    >
                    X
                  </button>
                  <div id="powerStuff" class="field-block my-4 hover-scale bg-50-white border">
                    <div id="powerContainer ">
                      <div class="label block p-2">
                        <label class="font-bold inline-block">Powers: </label>
                        <button class="bg-green-400 text-white py-0 px-1 rounded inline-block" style="float:right; cursor:pointer;" @click="addField(charPowers, 'powersField')">Add</button>
                      </div>
                      <div class="p-2 flex flex-row justify-around" v-for="(power, index) in charPowers" :key="index">
                        <span class="inline-block pt-1 bold">{{index+1}}</span>
                        <textarea
                          class="powers inline-block transparent-bg rounded input border p-2 mb-1 w-11/12"
                          style="font-size:14px;height:34px; min-height:34px;"
                          v-model="charPowers[index]"
                          @keyup="checkField(charPowers, 'powers')"
                          :placeholder="power"
                        />
                      </div>
                    </div>
                    <input
                      class="inline-block"
                      id="powersField"
                      placeholder="new power"
                      style="pointer-events:none; display:none;"
                    />
                  </div>
                  <div id="maneuverStuff" class=" field-block my-4 hover-scale bg-50-white border">
                    <div id="maneuverContainer">
                      <div class="label block p-2">
                        <label class="font-bold inline-block">Manuevers: {{ this.charManeuvers.length || 0}} </label>
                        <button
                          class="bg-green-400 text-white py-0 px-1 rounded inline-block"
                          style="float:right; cursor:pointer;"
                          @click="addField(charManeuvers, 'maneuversField')"
                        >
                          Add
                        </button>
                      </div>
                      <div class="p-2 flex flex-row justify-around" v-for="(maneuver, index) in charManeuvers" :key="index">
                        <span class="inline-block pt-1 bold">{{index+1}}</span>
                        <textarea
                          class="maneuvers inline-block transparent-bg input border p-2 mb-1 w-11/12"
                          style="font-size:14px;height:34px; min-height:34px;"
                          v-model="charManeuvers[index]"
                          @keyup="checkField(charManeuvers, 'maneuvers')"
                          :placeholder="maneuver"
                        />
                      </div>
                    </div>
                    <input class="inline-block" id="maneuversField" placeholder="new maneuver" style="pointer-events:none; display:none;"/>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="w-1/6 block px-6 mb-12 absolute" style="z-index:9; right:10px; top:78px;">
      <button
        class="bg-red-500 text-white font-bold p-1 mx-2 rounded inline-block"
        style="font-size:1em;"
        v-if="characterId && onEditPage"
        @click="deleteItemCheck">
          Delete
      </button>
    </div>
    <div v-if="showModal" class="bg-white rounded w-2/5 mx-auto shadow p-10 absolute" style="left:30%; top:calc(0px + 20vh);">
      <p class="my-10">Are you sure you want to delete this?</p>
      <div class="flex flex-row justify-between">
        <button class="bg-blue-500 text-white font-bold py-2 px-4 rounded inline-block" @click="toggleDelete">Nope!</button>
        <button class="bg-red-500 text-white font-bold py-2 px-4 rounded inline-block" @click="deleteItem">Yep!</button>
      </div>
    </div>
  </div>
</template>

<script>
// import Loading from '../UI/Loading.vue'

export default {
  name: 'PlayerCharacter',
  components: {
  },
  props: {
    editPage: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      onEditPage: false,
      error: '',
      notice: '',
      user: {},
      data: '',
      userCharacter: null,
      userId: this.$store.getters.currentUserId,
      characterId: this.$store.state.characterId,
      charName: null,
      charDescription: "",
      charBackground: "",
      charNotes: "",
      charCompetence: "",
      charItems: "",
      charHistory: "",
      charProfessions: "",
      charRecreations: "",
      charPoints: 500,
      spentPoints: 0,
      charManeuvers: [],
      charPowers: [],
      Perception: 50,
      Coordination: 50,
      Intuition: 50,
      Wit: 50,
      Culture: 50,
      VirtueVice: 50,
      Empathy: 50,
      Mobility: 50,
      Brawn: 50,
      Vigor: 50,
      tempPer: 0,
      tempCor: 0,
      tempInt: 0,
      tempWit: 0,
      tempCul: 0,
      tempVir: 0,
      tempEmp: 0,
      tempMob: 0,
      tempBra: 0,
      tempVig: 0,
      modifiedPer: 0,
      modifiedCor: 0,
      modifiedInt: 0,
      modifiedWit: 0,
      modifiedCul: 0,
      modifiedVir: 0,
      modifiedEmp: 0,
      modifiedMob: 0,
      modifiedBra: 0,
      modifiedVig: 0,
      showModal: false,
      totalHist: "",
      currenthist: "",
      inputImage: null,
      character: [],
      charImageUrl: null,
      editable: true,
      rollable: true,
      lastRoll: "",
      newFields: [],
      newSelect: [],
      newManueverFieldId: 0,
      toggleLoading: false,
      hasOverSpent: false,
      customDieValue: 0,
      activeShow: "description",
      editAbilites: false,
      editCompetence: false,
      shiftCharacterBar: true
    }
  },
  created () {
    this.checkSignedIn();
    this.updateStat();
    if ( window.location.href.includes("edit") ) {
      this.onEditPage = true;
    }
  },
  mounted () {
    this.getCharacter();
    this.updateStat();
    this.pointSpend();
    //this.roll(this.customDieValue, "custom");
  },
  methods: {
    addCharImage (index) {
      this.sceneIndex = index;
      this.$refs.inputPic.click();
    },
    toggleBar () {
      this.shiftCharacterBar = !this.shiftCharacterBar;
    },
    toggleEditCompetence() {
      this.editCompetence = !this.editCompetence;
      if (this.editAbilites === true) {
         this.editAbilites = !this.editAbilites;
      }
    },
    toggleEditAbilities() {
      this.editAbilites = !this.editAbilites;
       if (this.editCompetence === true) {
        this.editCompetence = !this.editCompetence;
      }
    },
    hideEdit() {
       this.editAbilites = false;
       this.editCompetence = false;
    },
    toggleShow (id) {
      this.activeShow = id;
    },
    updateStat () {
      this.modifiedPer = parseInt(this.Perception) + parseInt(this.tempPer)
      this.modifiedCor = parseInt(this.Coordination) + parseInt(this.tempCor)
      this.modifiedInt = parseInt(this.Intuition) + parseInt(this.tempInt)
      this.modifiedWit = parseInt(this.Wit) + parseInt(this.tempWit)
      this.modifiedCul = parseInt(this.Culture) + parseInt(this.tempCul)
      this.modifiedVir = parseInt(this.VirtueVice) + parseInt(this.tempVir)
      this.modifiedEmp = parseInt(this.Empathy) + parseInt(this.tempEmp)
      this.modifiedMob = parseInt(this.Mobility) + parseInt(this.tempMob)
      this.modifiedBra = parseInt(this.Brawn) + parseInt(this.tempBra)
      this.modifiedVig = parseInt(this.Vigor) + parseInt(this.tempVig)
    },
    dieValue () {
      const customVal = document.querySelector("#customDie")
      return parseInt(customVal.value)
    },
    checkFieldTypes(field, section) {
      if (typeof(field) === "string" ){
        const storVar = field
        field = []
        field.push(storVar)
        if (section === "maneuvers"){
          this.charManeuvers = field
        }else if (section === "powers") {
          this.charPowers = field
        }
      }
    },
    checkField (section, select) {
      this.newSection = section
      const fields = document.getElementsByClassName(select)
      fields.forEach((field, index) => {
        if (this.newSection[index] === "" ){
          // Removes any section that isnt filled in
          this.newSection.splice(index, 1)
        }
      })
      if (select === "maneuvers"){
        this.charManeuvers = this.newSection
      }else if (select === "powers") {
        this.charPowers = this.newSection
      }
      this.updateCharacter()
      this.pointSpend()
    },
    addField (field, section) {
      const x = document.getElementById(section);
      this.newFields = field
      this.newFields.push(x.value);
      if (section === "maneuvers"){
        this.charManeuvers = this.newFields
      }else if (section === "powers") {
        this.charPowers = this.newFields
      }
      x.value = ""
      this.pointSpend()
      this.updateCharacter()
    },
    pointSpend() {
      const compTotal = parseInt(this.Perception) + parseInt(this.Coordination)+parseInt(this.Intuition)+parseInt(this.Wit)+parseInt(this.Culture)+parseInt(this.VirtueVice)+parseInt(this.Empathy)+parseInt(this.Mobility)+parseInt(this.Brawn)+parseInt(this.Vigor)
      const manTotal = this.charManeuvers.length * 10
      const powTotal = this.charPowers.length * 10
      // const talentTotal = this.charTalents.length * 5
      this.spentPoints = compTotal + manTotal + powTotal
      if (this.charPoints < this.spentPoints) {
        this.hasOverSpent = false;
      } else {
        this.hasOverSpent = true
      }
    },
    newCharacter () {
      return Boolean(this.characterId === 0)
    },
    changePic () {
      if (this.onEditPage) {
        document.getElementById('characterImage').click();
      }
    },
    async uploadImage() {
      this.inputImage = this.$refs.inputPic.files[0];
      const imageparams = { 'image': this.inputImage }
      let formData = new FormData()
      Object.entries(imageparams).forEach(
        ([key, value]) => formData.append(key, value)
      )
      //this should be handled with computed??
      if (this.inputImage) {
       await this.$http.uploadFile.patch(`/characters/${this.characterId}`, formData)
        .then(response => this.updateSuccessful(response), window.location.reload() )
        .catch(error => this.setError(error, 'Something went wrong'))
        this.$router.go()
      }
    },
    roll (stat, id) {
      this.updateStat();
      this.rollable = false
      setTimeout(() => {
        const history = document.getElementById("history");
        // might need to use ref here
        const history2 = document.querySelector("#history2");
        const currentDate= new Date().toLocaleDateString("en-US");
        const currentTime= new Date().toLocaleTimeString("en-US");
        let newestRoll = ""
        let fontColor
        let val = 0;
        if (id === "custom") {
          val = (Math.floor(Math.random() * stat) + 1);
          newestRoll = `<span class="font-bold bg-sd-dark-blue pl-1 rounded" style="color:white;font-size: 1.5em; color: white;">${val} </span><span class=" white-font font-bold">@ ${currentTime} ${currentDate} </i></span></br><br>`
          history2.innerHTML= newestRoll
          this.currentHist = `<span class="sd-dark-blue-font" style="font-size: 14px; opacity:0.7; color:white;"><span class="font-bold">${val} </span> <i>on</i> ${id} <span class=" white-font font-bold"></span><i">@ ${currentTime} ${currentDate} </i></span> </br>`
        } else {
          val = this.rollValue(stat)
          fontColor = val.passBool ? "sd-font-light-green":"text-red-500";
          newestRoll = `<span class="font-bold bg-sd-dark-blue pl-1 rounded ${fontColor}" style="font-size: 1.5em;">${val.rollVal} </span><span class="font-bold"> &nbsp ${val.passSuccess}</span> by ${Math.abs(val.passVal)} <i>on</i> ${id}</br><br>`
          history2.innerHTML= `<span class="font-bold bg-sd-dark-blue pl-1 rounded ${fontColor}" style="font-size: 1.25em;">${val.rollVal} </span><span style="font-size: .8em;"> &nbsp ${val.passSuccess}</span> by ${Math.abs(val.passVal)} <i>on</i> ${id}</br><br>`
          this.currentHist = `<span style="font-size: 14px; opacity:0.7; color:white;"><span class="font-bold ${fontColor}">${val.rollVal} </span> <i>on</i> ${id} <span class="font-bold">${val.passSuccess}</span><i">@ ${currentTime} ${currentDate} </i></span> </br>`
        }
        history.innerHTML = this.totalHist
        this.totalHist = this.currentHist + this.totalHist
        this.rollable = true;
      }, 850)

    },
    rollValue (stat) {
      const rollVal = (Math.floor(Math.random() * 100) + 1);
      const passVal = rollVal - stat;
      const passBool = passVal < 0 ? true : false
      const passSuccess = passVal < 0 ? "SUCCESS" : "FAIL"

      return {
        rollVal: rollVal,
        passVal: passVal,
        passBool: passBool,
        passSuccess: passSuccess
      }
    },
    toggleDelete () {
      this.showModal = !this.showModal;
    },
    deleteItemCheck () {
      this.toggleDelete();
    },
    updateComps () {
      this.pointSpend()
      this.charCompetence = {
        perception: this.Perception || 0,
        coordination: this.Coordination || 0,
        intuition: this.Intuition || 0,
        wit: this.Wit || 0,
        culture: this.Culture || 0,
        virtueVice: this.VirtueVice || 0,
        empathy: this.Empathy || 0,
        mobility: this.Mobility || 0,
        brawn: this.Brawn || 0,
        vigor: this.Vigor || 0,
        tempPer: this.tempPer,
        tempCor: this.tempCor,
        tempInt: this.tempInt,
        tempWit: this.tempWit,
        tempCul: this.tempCul,
        tempVir: this.tempVir,
        tempEmp: this.tempEmp,
        tempMob: this.tempMob,
        tempBra: this.tempBra,
        tempVig: this.tempVig
      }
      this.updateCharacter();
    },
    deleteItem () {
      this.$http.plain.delete(`/characters/${this.characterId}`)
      this.$store.state.postId = null
      window.location = "/account";
    },
    updateSuccessful (response) {
      this.data = response;
      this.notice = 'Character updated'
      this.error = ''
    },
    updateFailed (error) {
      this.error = (error.response && error.response.data && error.response.data.error) || ''
      this.notice = ''
    },
    setError (error, text) {
      this.error = (error.response && error.response.data && error.response.data.error) || text
      this.notice = ''
    },
    checkSignedIn () {
      if (!this.$store.state.signedIn && this.$store.state.currentUser.role !== "admin") {
        this.$router.replace('/');
      }else if(this.$store.state.characterId){
        this.characterId = this.$store.state.characterId
        this.getCharacter()
      }else {
        return
      }
    },
    getCharacter () {
      this.$http.plain.get(`/users/${this.userId}/characters`)
        .then(response => {
          const char = response.data
          this.userCharacter = char.find(x => x.id === this.characterId )
          this.charImageUrl = this.userCharacter.image_url || '/img/sandragon_orange.8c62507b.png'
          this.userCharacter = JSON.parse(this.userCharacter.payload)
          this.charName = this.userCharacter.name
          this.charDescription = this.userCharacter.description
          this.charBackground = this.userCharacter.background
          this.charNotes = this.userCharacter.notes
          this.charCompetence = this.userCharacter.competence
          this.charItems = this.userCharacter.items
          this.charHistory = this.userCharacter.history
          this.charPowers = this.userCharacter.powers
          this.checkFieldTypes(this.charPowers, "powers")
          this.charProfessions = this.userCharacter.professions

          this.charManeuvers = this.userCharacter.maneuvers
          this.checkFieldTypes(this.charManeuvers, "maneuvers")
          this.charPoints = this.userCharacter.points
          this.spentPoints = this.userCharacter.spentPoints
          this.charRecreations = this.userCharacter.recreations
          this.Perception = this.charCompetence.perception
          this.Coordination = this.charCompetence.coordination
          this.Intuition = this.charCompetence.intuition
          this.Wit = this.charCompetence.wit
          this.Culture = this.charCompetence.culture
          this.VirtueVice = this.charCompetence.virtueVice
          this.Empathy = this.charCompetence.empathy
          this.Mobility = this.charCompetence.mobility
          this.Brawn = this.charCompetence.brawn
          this.Vigor = this.charCompetence.vigor
          this.tempPer = this.charCompetence.tempPer
          this.tempCor = this.charCompetence.tempCor
          this.tempInt = this.charCompetence.tempInt
          this.tempWit = this.charCompetence.tempWit
          this.tempCul = this.charCompetence.tempCul
          this.tempVir = this.charCompetence.tempVir
          this.tempEmp = this.charCompetence.tempEmp
          this.tempMob = this.charCompetence.tempMob
          this.tempBra = this.charCompetence.tempBra
          this.tempVig = this.charCompetence.tempVig
          this.updateSuccessful(response)
        })
      .catch(error => this.setError(error, 'Something went wrong'))
    },
    updateCharacter () {
      this.toggleLoading = true;
      let payload = {
        name: this.charName,
        description: this.charDescription,
        background: this.charBackground,
        notes: this.charNotes,
        items: this.charItems,
        powers: this.charPowers,
        history: this.charHistory,
        maneuvers: this.charManeuvers,
        recreations: this.charRecreations,
        professions: this.charProfessions,
        points: this.charPoints,
        spentPoints: this.spentPoints,
        competence: this.charCompetence
      };

      payload = JSON.stringify(payload);
      if (this.characterId === 0) {
        this.$http.plain.post("/characters/", {
          user_id: this.userId,
          payload
        })
        .then(response => this.updateSuccessful(response))
        .catch(error => this.updateFailed(error))
        window.location = "/account";
      } else {
        this.$http.plain.patch(`/characters/${this.characterId}`, {
         payload
        })
        .then(response => this.updateSuccessful(response))
        .catch(error => this.updateFailed(error))
      }
      setTimeout(() => {
        this.toggleLoading = false;
      }, 2000)

    }
  },
}
</script>

<style scoped lang="scss">
  .full-width-sheet {
    left: 0% !important;
    right: -20% !important;
  }
  .image-container {
    padding: 2rem;

    img {
      height: 100%;
      margin-top: 2rem;
      width: 100%;
    }
    .image-active {
      display: block;
    }
    .image-inactive {
      display: none;
    }
  }

  .icon-hover {
    padding: 1px;
    border: 1px solid rgba(0,0,0,0.0);
  }
  .icon-hover:hover {
    border: 1px solid orange;
    border-radius: 15%;
  }
  .edit-abilities, .edit-competence {
    background: white;
    pointer-events: none;
    cursor: unset;
    position: fixed;
    top: 27%;
    left: -56%;
    width: 75%;
    max-width: 800px;
    opacity: 0;
    transition: left 1s, opacity 1s;
    z-index: 100;
    overflow: scroll;
  }
  .show-edit-abilities, .show-edit-competence {
    pointer-events: unset;
    cursor: pointer;
    z-index: 101;
    width: 100%;
    height: 100%;
    opacity: 1;
    left: 26%;
    top: calc(100vh - 100%);
    transition: left 1s, opacity 1s;
  }
  .hide {
    display: none;
  }
  .show {
    display: unset;
  }
  .textarea75 {
    height: 50px;
  }
  .textarea75:hover {
    background-color: white;
    opacity: 1 !important;
  }
  .white-font {
    color:white;
  }
  .field-block {
    button {
      opacity: 0;
      transition: opacity 500ms
    }
  }
  .field-block:hover {
    button {
      opacity: 1;
      transition: opacity 500ms
    }
  }
  .red-font {
    color: rgb(159, 15, 15);
  }
  .green-font {
    color: rgb(26, 90, 26);
  }
  .loader {
    position: absolute;
    z-index: 20;
    transform: scale(12%);
    top: -92px !important;
    left: 40%;
    transition: opacity 750ms;
  }
  .comp-grid {
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;

    p {
      position:relative;
      background-color:#0F102F;
      color: white;

      .stat-label {
        position: absolute;
        display:unset;
      }
      .stat {
        color:white;
        position:absolute;
        display:none;
      }
    }

    p:hover {
      transform:scale(110%);
      transition: scale 500ms;
      color: orange !important;
      .stat-label {
        // display: none;
        color: orange !important;
      }
      .stat {
        color: orange !important;
        // display:show;
        top: 8px;
        right: 16px;
        font-size: 1.1em;
      }
    }

  }
  .transparent-bg {
    background-color: rgba(0,0,0,0.0);
  }

  .bg-50-white {
    background-color: rgba(252, 252, 252, 0.7);
  }

  .light-bg-back {
    background-color: #d9e1ef;
  }

  .textlines {
    font-size: 15px; line-height: 27px;
    padding: 0px 5px; margin: 8px;
  }

  .textlines {
    background: repeating-linear-gradient( to bottom, transparent, transparent 26px, #33d 27px);
    background-attachment: local;
    border: none; outline: none;
  }

  label, .label {
    height: max-content;
    background-color: #d9e1ef;
    padding-left: .5em;
  }

  .textarea150 {
    min-height: 150px;
  }

  .character-image {
    position: relative;
    .hidden-text {
      color: #fff;
      display: none;
      font-size: 0.9rem;
      font-weight: 600;
      left: 20%;
      position: absolute;
      top: 35%;
    }
  }

  .character-image:hover {
    .img {
      border-radius: 50%;
      filter: grayscale(1);
      opacity: 0.4;
    }
    .hidden-text {
      display: unset;
    }
  }

  .name-box:hover {
    background-color: orange;
  }
  .shadow {
    box-shadow: 6px 9px 18px 1px rgba(121,116,120,0.74);
    -webkit-box-shadow: 6px 9px 18px 1px rgba(121,116,120,0.74);
  }
  .hover-scale:hover {
    box-shadow: 6px 9px 18px 1px rgba(121,116,120,0.74);
    -webkit-box-shadow: 6px 9px 18px 1px rgba(121,116,120,0.74);
    transform: scale(102%);
    transition: transform 350ms;

    label {
      background-color: #a2bcea;
      transition: background-color 350ms;
    }
  }


  .dot-paper {
    background-image: radial-gradient(circle, #000000 1px, rgba(0, 0, 0, 0) 1px);
    background-size: 40px 40px;
  }

  .grid-paper {
    background-color: #000000;
    background-size: 30px 30px;
    background-image:
      linear-gradient(to right, rgba(255, 255, 255, 0.75) 1px, transparent 1px),
      linear-gradient(to bottom, rgba(255, 255, 255, 0.75) 1px, transparent 1px);
  }

  .pointer-events-none {
    cursor: none;
  }

  //animation for dice
  .lds-circle {
  display: inline-block;
  transform: translateZ(1px);
}
.lds-circle > div {
  display: inline-block;
  width: 48px;
  height: 48px;
  margin: 8px;
  border-radius: 50%;
  background:  rgb(4, 16, 104);
  animation: lds-circle 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
@keyframes lds-circle {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
  }
  0% {
    background:  rgb(4, 16, 104);
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(1800deg);
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
  }
  100% {
    transform: rotateY(3600deg);
  }
}
</style>