<template>
  <header class="p-3 grid grid-cols-2 gap-0 relative" id="header" :class="[!isUserScrolling ? 'bg-true' : 'bg-false']" style="position: fixed; z-index: 3; width: 100%; height: 74px;">
    <div class="container flex flex-wrap items-center justify-start bg-sd-dark-blue absolute" style="clip-path: polygon(260px 0, 220px 45%, 260px 100%, 0 260px, 0% 45%, 0 0); z-index: 10;">
      <router-link to="/" class="text-white px-2 no-underline flex nav-link p-3" >
        <img src="../../assets/sandragon_orange.png" class="m-auto" style="width: 40px;">
        <p class="inline-block my-2 text-2xl sd-font-orange" style="transform:translate(10px, calc(50% - 14px));">SANDRAGON</p>
      </router-link>
    </div>
    <div class="w-full flex flex-row items-center justify-end absolute p-3" style="height:74px;">
      <div>
        <!-- <p class="sm-hide text-white px-2 no-underline inline block nav-link" v-if="!signedIn()" @click="checkUrl">Sign in</p> -->
        <!-- <router-link to="/signin" class="text-white px-2 no-underline mobile-font"  v-if="!signedIn()" >Sign in</router-link> -->

        <div class="dropdown rounded-lg" v-if="signedIn()">

          <div class="nav-link">
            <div class="mobile-nav nav-link" @click="toggleMobile()">
              <svg xmlns="http://www.w3.org/2000/svg" class="inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" style="width: 40px; height: 40px;">
              <path stroke-linecap="round" stroke-linejoin="round" d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </div>
            <div class="username">
              <p class="inline-block text-white nav-link  mr-1" style="font-size: 0.85rem;">{{this.$store.state.currentUser.username}}</p>
              <img :src="this.$store.state.currentUserImage" class="w-7 h-7 ml-2 bg-gray-900 rounded-full inline-block ml-2"/>
            </div>
          </div>

          <div class="dropdown-content bg-sd-light-blue p-2 rounded">
            <div class="large-dropdown">
              <span class="animate-nav">
                <router-link to="/content" class="sd-font-dark-blue px-2 no-underline mobile-font">Content</router-link>
                <hr class="under-nav">
              </span>
              <span class="animate-nav">
                <router-link to="/posts" class="sd-font-dark-blue px-2 no-underline mobile-font" v-if="signedIn() && showAdminLink()">Posts Edit</router-link>
                <hr class="under-nav">
              </span>
              <span class="animate-nav">
                <router-link to="/account" class="sd-font-dark-blue px-2 no-underline mobile-font">Account</router-link>
                <hr class="under-nav">
              </span>
              <span class="animate-nav">
                <router-link to="/wiki" class="sd-font-dark-blue px-2 no-underline mobile-font" v-if="signedIn() && showAdminLink()">Wiki</router-link>
                <hr class="under-nav">
              </span>
              <span class="animate-nav">
                <router-link to="/table" class="sd-font-dark-blue px-2 no-underline mobile-font" v-if="signedIn() && showAdminLink()">Table</router-link>
                <hr class="under-nav">
              </span>
              <span class="animate-nav">
                <a @click.prevent="signOut" class="sd-font-dark-blue px-2 no-underline mobile-font" v-if="signedIn()" style="cursor:pointer;">Sign out</a>
                <hr class="under-nav">
              </span>
            </div>
            <div class="mobile-dropdown" v-if="mobileToggle" v-on-clickaway="toggleMobile" >
              <p class="inline-block text-white mr-1 mobile-fon mobile-label" style="font-size: 0.85rem;">{{this.$store.state.currentUser.username}}</p>
              <span class="animate-nav">
                <router-link to="/content" class="sd-font-dark-blue px-2 no-underline mobile-font">Content</router-link>
                <hr class="under-nav">
              </span>
              <span class="animate-nav">
                <router-link to="/posts" class="sd-font-dark-blue px-2 no-underline mobile-font" v-if="signedIn() && showAdminLink()">Posts Edit</router-link>
                <hr class="under-nav">
              </span>
              <span class="animate-nav">
                <router-link to="/account" class="sd-font-dark-blue px-2 no-underline mobile-font">Account</router-link>
                <hr class="under-nav">
              </span>
              <span class="animate-nav">
                <router-link to="/wiki" class="sd-font-dark-blue px-2 no-underline mobile-font" v-if="signedIn() && showAdminLink()">Wiki</router-link>
                <hr class="under-nav">
              </span>
              <span class="animate-nav">
                <router-link to="/table" class="sd-font-dark-blue px-2 no-underline mobile-font" v-if="signedIn() && showAdminLink()">Table</router-link>
                <hr class="under-nav">
              </span>
              <span class="animate-nav">
                <a @click.prevent="signOut" class="sd-font-dark-blue px-2 no-underline mobile-font" v-if="signedIn()" style="cursor:pointer;">Sign out</a>
                <hr class="under-nav">
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import debounce from 'lodash/debounce';

export default {
  name: 'Header',

  data() {
    return {
      toggle: false,
      mobileToggle: false,
      currentScroll: window.pageYOffset,
      isUserScrolling: false,
    }
  },
  created () {
    this.signedIn();
  },
  mounted () {
    window.addEventListener('scroll',() => {
      this.mobileToggle = false;
    });
    this.handleDebouncedScroll = debounce(this.handleScroll, 100);
    window.addEventListener('scroll', this.handleDebouncedScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleDebouncedScroll);
  },
  methods: {
    handleScroll() {
      this.isUserScrolling = (window.scrollY > 38);
    },
    toggleMobile() {
      this.mobileToggle = !this.mobileToggle
      return this.mobileToggle
    },
    setError (error, text) {
      this.error = (error.response && error.response.data && error.response.data.error) || text
    },
    signedIn () {
      return this.$store.state.signedIn
    },
    signOut () {
      this.$http.secured.delete('/signin')
        .then(response => {
          this.$store.commit('unsetCurrentUser')
          this.$router.go('/')
          if (response) {
            console.log("Player removed")
          }

        })
        .catch(error => this.setError(error, 'Cannot sign out'))
    },
    showAdminLink () {
      return this.$store.getters.isAdmin
    },
    checkUrl() {
      if (window.location.href.indexOf("signin") > -1 || window.location.href.indexOf("signup") > -1) {
        return
      } else {
        this.$emit('toggleSignin')
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .bg-true {
    background-color: #0F102F;
    transition: background-color 1500ms
  }
  .bg-false {
    background-color: rgb(255, 255, 255, 0.0);
    transition: background-color 1500ms
  }

 @media (max-width: 640px) {
    .username {
      display:none;
    }
    .mobile-nav {
      display: inline;
      left: -11vw;
      position: relative;
      color: #ED7126;
    }
    .mobile-font {
      font-size: 1.25rem;
    }
    .dropdown-content {
      width: 50vw;
      left: -23vw !important;
    }
    .large-dropdown {
      display:none;
    }
    .mobile-dropdown {
      display:unset;
    }
  }
  @media (min-width: 641px) {
    .mobile-nav {
      display: none;
    }
    .mobile-label {
      display: none;
    }
    .mobile-dropdown {
      display:none;
    }
    .large-dropdown {
      display:unset;
    }
  }
.dropdown-content {
    width: 40vw;
}
.under-nav {
    height: 2px;
    border-top: 2px solid #ED7126;
    width: 0;
    // transition: width 300ms;
    // transform:translate(70%, 0);
    transition: width .5s;
  }

.animate-nav:hover {
  .under-nav {
    width: 100px;
    // transition: width 300ms;
    // transform:translate(70%, 0);
    transition: width .5s;
  }
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  left: 0px;
  z-index: 1;
}

.dropdown-content a {
  padding: 12px 0;
  text-decoration: none;
  display: block;
}

.dropdown:hover .dropdown-content {
  display: block;
  text-decoration: none;
}
.nav-link:hover {
  color: #ED7126;
}

</style>
