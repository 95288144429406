<template>
  <div class="items-center text-center py-20">
    <div
      v-for="post in posts"
      :key="post.id"
      :post="post">
      <div v-if="post.slug === slug">
        <p class="my-8 semibold text-2xl"> {{ post.title }} </p>
        <img :src="post.image_url" class="sm:w-4/5 md:w-3/5 lg:w-2/5 px-8 mx-auto" />
        <div class="mx-auto sm:w-4/5 md:w-3/5 text-center p-4 mt-4">
          <p> {{ post.body }} </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
     postId: null,
     post: [],
     posts: [],
     slug: ""
    }
  },
  async created () {
    await this.$http.secured.get("/posts")
      .then( response => { this.posts = response.data } )
      .catch( error => this.setError( error, 'Something went wrong' ) )

    this.checkSlug();
  },
  methods: {
    isAdmin () {
      return this.$store.getters.isAdmin;
    },
    routeTo (){
      window.location = "/posts/edit";
    },
    isSignedIn () {
      return this.$store.state.signedIn
    },
    checkSlug () {
      //fix this
      const origin = window.location.origin;
      if ( origin === "http://localhost:8080" ) {
        this.slug = window.location.href.slice(28);
      } else if (origin === "https://www.sandragon.io") {
        //this needs fixed fix
        this.slug = window.location.href.slice(31);
      } else if (origin === "https://sandragon-front-ucexa.ondigitalocean.app") {
        this.slug = window.location.href.slice(53);
      }
      let match = false;
      this.posts.forEach(element =>{
        if ( element.slug == this.slug ) {
          match = true;
          return;
        }
      });
      if ( match === false ) {
        window.location = "/";
      }
    }
  }
}
</script>
